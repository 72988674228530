import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ReactComponent as Ads } from "../assets/svg/ads.svg";
import { ReactComponent as ArrowDown } from "../assets/svg/arrow-down.svg";
import { ReactComponent as ArrowDropDown } from "../assets/svg/arrow-drop-down.svg";
import { ReactComponent as ArrowDropUp } from "../assets/svg/arrow-drop-up.svg";
import { ReactComponent as ArrowLeft } from "../assets/svg/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../assets/svg/arrow-right.svg";
import { ReactComponent as ArrowUp } from "../assets/svg/arrow-up.svg";
import { ReactComponent as Balloon } from "../assets/svg/balloon.svg";
import { ReactComponent as Calendar } from "../assets/svg/calendar.svg";
import { ReactComponent as Camera } from "../assets/svg/camera.svg";
import { ReactComponent as Check } from "../assets/svg/check.svg";
import { ReactComponent as Checkbox } from "../assets/svg/checkbox.svg";
import { ReactComponent as CheckboxBlank } from "../assets/svg/checkbox-blank.svg";
import { ReactComponent as CheckboxPart } from "../assets/svg/checkbox-part.svg";
import { ReactComponent as ChevronLeft } from "../assets/svg/chevron-left.svg";
import { ReactComponent as CloudPlay } from "../assets/svg/cloud-play.svg";
import { ReactComponent as Copy } from "../assets/svg/copy.svg";
import { ReactComponent as Dashboard } from "../assets/svg/dashboard.svg";
import { ReactComponent as Delete } from "../assets/svg/delete.svg";
import { ReactComponent as Done } from "../assets/svg/done.svg";
import { ReactComponent as Download } from "../assets/svg/download.svg";
import { ReactComponent as Edit } from "../assets/svg/edit.svg";
import { ReactComponent as PlaylistPlay } from "../assets/svg/playlist_play.svg";
import { ReactComponent as EllipsisHorizontal } from "../assets/svg/ellipsis-horizontal.svg";
import { ReactComponent as EllipsisVetical } from "../assets/svg/ellipsis-vertical.svg";
import { ReactComponent as Equalizer } from "../assets/svg/equalizer.svg";
import { ReactComponent as File } from "../assets/svg/file.svg";
import { ReactComponent as Flag } from "../assets/svg/flag.svg";
import { ReactComponent as Headset } from "../assets/svg/headset.svg";
import { ReactComponent as Help } from "../assets/svg/help.svg";
import { ReactComponent as History } from "../assets/svg/history.svg";
import { ReactComponent as Info } from "../assets/svg/info.svg";
import { ReactComponent as Location } from "../assets/svg/location.svg";
import { ReactComponent as Lock } from "../assets/svg/lock.svg";
import { ReactComponent as Login } from "../assets/svg/login.svg";
import { ReactComponent as Logout } from "../assets/svg/logout.svg";
import { ReactComponent as Menu } from "../assets/svg/menu.svg";
import { ReactComponent as Logo } from "../assets/svg/logo.svg";
import { ReactComponent as MenuOpen } from "../assets/svg/menu_open.svg";
import { ReactComponent as Microphone } from "../assets/svg/microphone.svg";
import { ReactComponent as Minus } from "../assets/svg/minus.svg";
import { ReactComponent as Move } from "../assets/svg/move.svg";
import { ReactComponent as Music } from "../assets/svg/music.svg";
import { ReactComponent as Newspaper } from "../assets/svg/newspaper.svg";
import { ReactComponent as Pause } from "../assets/svg/pause.svg";
import { ReactComponent as Play } from "../assets/svg/play.svg";
import { ReactComponent as Close } from "../assets/svg/close.svg";
import { ReactComponent as Plus } from "../assets/svg/plus.svg";
import { ReactComponent as Mail } from "../assets/svg/mail.svg";
import { ReactComponent as Search } from "../assets/svg/search.svg";
import { ReactComponent as Signal100 } from "../assets/svg/signal-100.svg";
import { ReactComponent as Signal75 } from "../assets/svg/signal-75.svg";
import { ReactComponent as Cloud } from "../assets/svg/cloud.svg";
import { ReactComponent as Signal50 } from "../assets/svg/signal-50.svg";
import { ReactComponent as Signal25 } from "../assets/svg/signal-25.svg";
import { ReactComponent as SkipBack } from "../assets/svg/skip-back.svg";
import { ReactComponent as SkipForward } from "../assets/svg/skip-forward.svg";
import { ReactComponent as Star } from "../assets/svg/star.svg";
import { ReactComponent as Submenu } from "../assets/svg/submenu.svg";
import { ReactComponent as ThumbsDown } from "../assets/svg/thumbs-down.svg";
import { ReactComponent as Trash } from "../assets/svg/trash.svg";
import { ReactComponent as Vinyl } from "../assets/svg/vinyl.svg";
import { ReactComponent as VolumeHight } from "../assets/svg/volume-hight.svg";
import { ReactComponent as VolumeLow } from "../assets/svg/volume-low.svg";
import { ReactComponent as VolumeMute } from "../assets/svg/volume-mute.svg";
import { ReactComponent as Radio } from "../assets/svg/radio.svg";
import { ReactComponent as RadioBlank } from "../assets/svg/radio-blank.svg";
import { ReactComponent as LongArrow } from "../assets/svg/long-arrow.svg";
import { ReactComponent as Folder } from "../assets/svg/folder.svg";
import { ReactComponent as People } from "../assets/svg/people.svg";
import { ReactComponent as Person } from "../assets/svg/person.svg";
import { ReactComponent as Power } from "../assets/svg/power.svg";
import { ReactComponent as Eye } from "../assets/svg/eye.svg";
import { ReactComponent as Cancel } from "../assets/svg/cancel.svg";
import { ReactComponent as Place } from "../assets/svg/place.svg";
import { ReactComponent as MultilineChart } from "../assets/svg/multiline-chart.svg";
import { ReactComponent as FileAlt } from "../assets/svg/file-alt.svg";
import { ReactComponent as RadioReceiver } from "../assets/svg/radio-receiver.svg";
import { ReactComponent as TallArrowRight } from "../assets/svg/tall-arrow-right.svg";
import { ReactComponent as TallArrowLeft } from "../assets/svg/tall-arrow-left.svg";
import { ReactComponent as WiFi } from "../assets/svg/wifi.svg";
import { ReactComponent as ThumbDown } from "../assets/svg/thumb_down.svg";
import { ReactComponent as PlayCircle } from "../assets/svg/play_circle.svg";
import { ReactComponent as LightMode } from "../assets/svg/light_mode.svg";
import { ReactComponent as DarkMode } from "../assets/svg/dark_mode.svg";
import { ReactComponent as Stop } from "../assets/svg/stop.svg";
import { ReactComponent as PlayerLoading } from "../assets/svg/player_loading.svg";

const iconsByName = {
  ads: Ads,
  "arrow-down": ArrowDown,
  "arrow-drop-down": ArrowDropDown,
  "arrow-drop-up": ArrowDropUp,
  "arrow-left": ArrowLeft,
  "arrow-right": ArrowRight,
  "arrow-up": ArrowUp,
  balloon: Balloon,
  calendar: Calendar,
  camera: Camera,
  check: Check,
  checkbox: Checkbox,
  "checkbox-blank": CheckboxBlank,
  "checkbox-part": CheckboxPart,
  "chevron-left": ChevronLeft,
  "cloud-play": CloudPlay,
  dashboard: Dashboard,
  copy: Copy,
  delete: Delete,
  done: Done,
  download: Download,
  edit: Edit,
  "ellipsis-horizontal": EllipsisHorizontal,
  "ellipsis-vertical": EllipsisVetical,
  equalizer: Equalizer,
  file: File,
  flag: Flag,
  headset: Headset,
  help: Help,
  history: History,
  info: Info,
  location: Location,
  lock: Lock,
  login: Login,
  logout: Logout,
  menu_open: MenuOpen,
  microphone: Microphone,
  minus: Minus,
  move: Move,
  music: Music,
  newspaper: Newspaper,
  pause: Pause,
  mail: Mail,
  menu: Menu,
  play: Play,
  close: Close,
  plus: Plus,
  search: Search,
  "signal-100": Signal100,
  "signal-75": Signal75,
  "signal-50": Signal50,
  "signal-25": Signal25,
  "skip-back": SkipBack,
  "skip-forward": SkipForward,
  star: Star,
  "thumbs-down": ThumbsDown,
  submenu: Submenu,
  trash: Trash,
  vinyl: Vinyl,
  "volume-hight": VolumeHight,
  "volume-low": VolumeLow,
  "volume-mute": VolumeMute,
  radio: Radio,
  "radio-blank": RadioBlank,
  "tall-arrow-left": TallArrowLeft,
  "tall-arrow-right": TallArrowRight,
  "long-arrow": LongArrow,
  folder: Folder,
  people: People,
  person: Person,
  power: Power,
  eye: Eye,
  cancel: Cancel,
  place: Place,
  "multiline-chart": MultilineChart,
  "file-alt": FileAlt,
  "radio-receiver": RadioReceiver,
  wifi: WiFi,
  logo: Logo,
  cloud: Cloud,
  playlistPlay: PlaylistPlay,
  thumbDown: ThumbDown,
  playCircle: PlayCircle,
  "light-mode": LightMode,
  "dark-mode": DarkMode,
  stop: Stop,
  "player-loading": PlayerLoading
};

export const Icon = ({ name, size, iconColor, iconColorHover }) => {
  const IconByName = iconsByName[name];

  return (
    <IconWrapper
      size={size}
      iconColor={iconColor}
      iconColorHover={iconColorHover}
    >
      <IconByName />
    </IconWrapper>
  );
};

Icon.defaultProps = {
  iconColor: "inherit",
  iconColorHover: "inherit",
  size: "2.4"
};

Icon.propTypes = {
  name: PropTypes.oneOf([
    "ads",
    "arrow-down",
    "arrow-drop-down",
    "arrow-drop-up",
    "arrow-left",
    "arrow-right",
    "arrow-up",
    "balloon",
    "calendar",
    "camera",
    "close",
    "check",
    "checkbox",
    "checkbox-blank",
    "checkbox-part",
    "chevron-left",
    "cloud-play",
    "dashboard",
    "copy",
    "delete",
    "done",
    "download",
    "edit",
    "ellipsis-horizontal",
    "ellipsis-vertical",
    "equalizer",
    "file",
    "flag",
    "headset",
    "help",
    "history",
    "info",
    "location",
    "lock",
    "login",
    "logout",
    "mail",
    "menu",
    "menu_open",
    "microphone",
    "minus",
    "move",
    "music",
    "newspaper",
    "pause",
    "play",
    "plus",
    "search",
    "signal-100",
    "signal-75",
    "signal-50",
    "signal-25",
    "skip-back",
    "skip-forward",
    "star",
    "submenu",
    "thumbs-down",
    "trash",
    "vinyl",
    "volume-hight",
    "volume-low",
    "volume-mute",
    "radio",
    "radio-blank",
    "tall-arrow-left",
    "tall-arrow-right",
    "long-arrow",
    "folder",
    "person",
    "people",
    "power",
    "eye",
    "cancel",
    "place",
    "multiline-chart",
    "file-alt",
    "radio-receiver",
    "wifi",
    "logo",
    "cloud",
    "playlistPlay",
    "thumbDown",
    "playCircle",
    "light-mode",
    "dark-mode",
    "stop",
    "player-loading"
  ]).isRequired,
  size: PropTypes.oneOf([
    "0.8",
    "1.0",
    "1.6",
    "2.0",
    "2.4",
    "3.2",
    "4",
    "4.8",
    "6",
    "6.4",
    "8",
    "11",
    "22"
  ]),
  iconColor: PropTypes.string,
  iconColorHover: PropTypes.string
};

const IconWrapper = styled.span`
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  position: relative;
  /* top: -0.1rem; */
  line-height: 0;
  width: ${props => props.size}rem;
  height: ${props => props.size}rem;

  color: ${props => props.iconColor};
  &:hover {
    opacity: 0.6;
  }
`;
