import React from "react";
import styled from "styled-components";

import { InstallAppButton, EPlatformType } from "./InstallAppButton";

export const MainContent = () => (
  <MainContentStyled>
    <TitleText>
      <span>Проигрывай музыку </span>
      <span>в мобильном приложении!</span>
    </TitleText>
    <List>
      <ListItem>Без прерываний</ListItem>
      <ListItem>Мгновенное удаление треков из волны</ListItem>
      <ListItem>Выбор музыки сразу в приложении</ListItem>
    </List>
    <InstallAppButton platform={EPlatformType.ANDROID} />
    <InstallAppButton platform={EPlatformType.IOS} />
  </MainContentStyled>
);

const MainContentStyled = styled.div`
  width: 100%;
  max-width: 600px;
`;

const TitleText = styled.h2`
  font-family: SB Sans Text;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
  margin: 0;

  @media (min-width: 1129px) {
    font-size: 20px;
    line-height: 26px;

    & > span {
      display: block;
    }
  }
`;

const List = styled.ul`
  padding-left: 20px;
  margin-bottom: 32px;
`;

const ListItem = styled.li`
  font-family: 'SB Sans Text';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #D8D2F7;

  @media (min-width: 1129px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const ExtraText = styled.span`
  font-family: 'SB Sans Text';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #D8D2F7;
  margin-top: 20px;
  display: block;

  @media (min-width: 1129px) {
    font-size: 12px;
    line-height: 16px;
  }
`;
