import React from "react";
import styled from "styled-components";
import { ContentLarge, H3, Icon } from "../../ui";
import Skeleton from "react-loading-skeleton";
import { useDislike, useStores } from "../../hooks";
import { observer } from "mobx-react";
import { Feedback } from "../common/information/feedback";

export const MobilePlayer = observer(
  ({ onlineMediafile, pointId, isLoggedIn, toggleAuthWindow }) => {
    const {
      SoundStore: { togglePlay, playing, disabled, buffering },
      CommonSettingsStore: { windowHeight },
      PointStore: {pointPresent},
    } = useStores();

    const handleClick = useDislike({
      mediafileId: onlineMediafile && onlineMediafile.id,
      pointId,
      isLoggedIn,
      toggleAuthWindow,
    });

    const onClick = () => {
      togglePlay();
    };

    return (
      <Wrapper
        style={{ height: `calc(${windowHeight}px - 10.4rem - 10.4rem)` }}
      >
        <PlayingSong>
          <Title>
            {(onlineMediafile && onlineMediafile.title) || (
              <Skeleton height={12} width={192} />
            )}
          </Title>
          <Author>
            {(onlineMediafile && onlineMediafile.performerTitle) || (
              <Skeleton height={12} width={256} />
            )}
          </Author>

          {onlineMediafile?.type === 0 && (
            <IconBox onClick={handleClick}>
              <Icon size="4.8" name="thumbDown" />
            </IconBox>
          )}
        </PlayingSong>

        <PlayPauseContainer
          disabled={disabled || !pointPresent}
          onClick={() => {
            if (!disabled && pointPresent) {
              onClick()
            }
          }}
          active={playing}
        >
          {buffering && playing ? (
            <Icon name="player-loading" size="8" iconColor={"#15bf95"} />
          ) : playing ? (
            <Icon name="stop" size="3.2" />
          ) : (
            <Icon name="play" size="6.4" />
          )}
        </PlayPauseContainer>
        <Feedback pointId={pointId} inline={true} />
      </Wrapper>
    );
  }
);

const PlayPauseContainer = styled.div`
  border: 2px solid #15bf95;
  border-radius: 50%;
  cursor: pointer;
  width: 9.6rem;
  height: 9.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.disabled && "opacity: 0.4; cursor: not-allowed;"};
  color: ${(props) =>
    props.active ? props.theme.activeTabIconColor : props.theme.iconColor};

  span {
    &:hover {
      opacity: 1;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
`;

const PlayingSong = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(H3)`
  margin-bottom: 1.7rem;
  color: ${(props) => props.theme.headingsColor};
  text-align: center;
`;

const Author = styled(ContentLarge)`
  color: ${(props) => props.theme.textColor};
  margin-bottom: 2.2rem;
`;

const IconBox = styled.div`
  color: ${(props) => props.theme.dislikeColor};
  &.active {
    color: ${(props) => props.theme.activeDislikeColor};
  }
`;
