import { createLogger } from "./logging";

const PLAYER_READY_WAIT_TIMEOUT_SEC = 60;

let APP_INIT_TIME: number;
let PLAYER_READY_TIMER: ReturnType<typeof setTimeout>;

const logger = createLogger('@metrics');

export function rememberAppInitTime() {
  APP_INIT_TIME = new Date().getTime();

  startWaitingForPlayerToBeReady();
}

export function logPlayerReadyTime() {
  const now = new Date().getTime();
  const timeMilliseconds = now - APP_INIT_TIME;

  logger.warn('Player is ready to start playback', {
    timeMilliseconds,
  });

  PLAYER_READY_TIMER && clearTimeout(PLAYER_READY_TIMER);
}

function startWaitingForPlayerToBeReady() {
  PLAYER_READY_TIMER = setTimeout(() => {
    logger.error(`Player is not ready more than ${PLAYER_READY_WAIT_TIMEOUT_SEC} seconds`);
  }, PLAYER_READY_WAIT_TIMEOUT_SEC * 1000);
}
