import React from "react";
import {useMutation} from "@apollo/react-hooks";
import {STREAM_DISLIKE_MEDIAFILE} from "../gql";
import {useStores} from "./useStores";

interface IUseDislikeProps {
  mediafileId: number
  pointId: number
  isLoggedIn: boolean
  toggleAuthWindow: () => void
}

export const useDislike = (
  {
    mediafileId,
    pointId,
    isLoggedIn,
    toggleAuthWindow,
  }: IUseDislikeProps) => {
  const {
    NotificationStore: {showNotification, closeNotification},
  } = useStores();

  const [submitDislike] = useMutation(STREAM_DISLIKE_MEDIAFILE);

  const handleClick = () => {
    if (!mediafileId) return;
    if (!isLoggedIn) {
      showNotification({
        type: "message",
        text: "Чтобы иметь возможность ставить дизлайки, войдите в систему.",
        timeout: null,
        customButtons: [
          <span
            onClick={() => {
              toggleAuthWindow();
              closeNotification();
            }}
          >
            Войти
          </span>,
          <span onClick={closeNotification}>Игнорировать</span>,
        ],
      });
      return;
    }

    submitDislike({variables: {mediafileId, pointId}})
      .then(handleSuccess)
      .catch(handleError);
  };

  const handleSuccess = () => {
    showNotification({
      type: "message",
      text: "Трек удален. Изменения вступят в силу через несколько минут.",
    });
  };

  const handleError = () => {
    showNotification({
      type: "error",
      timeout: null,
      text:
        "Вы не можете оценивать треки в этом потоке, так как не являетесь участником команды, владеющей им.",
    });
  };

  return handleClick;
};
