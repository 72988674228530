import React from "react";
import styled from "styled-components";

import { ReactComponent as AndroidIcon } from "../../assets/svg/android.svg";
import { ReactComponent as IosIcon } from "../../assets/svg/ios.svg";
import { ampli } from "../../ampli";

interface IInstallAppButtonProps {
  platform: EPlatformType;
}

export enum EPlatformType {
  ANDROID = "android",
  IOS = "ios",
}

const INSTALL_APP_LINK = "https://redirect.appmetrica.yandex.com/serve/100160811351211931?source_type=web-player";

export const InstallAppButton = ({platform}: IInstallAppButtonProps) => {
  const installAppClickHandler = () => {
    ampli.downloadAndroidPressed();

    window.open(INSTALL_APP_LINK);
  };

  return (
    <InstallAppButtonStyled onClick={installAppClickHandler} >
      { platform === EPlatformType.ANDROID ? (
        <>
          <AndroidIcon />
          Скачать для Android
        </>
      ) : (
        <>
          <IosIcon />
          Скачать для iOS
        </>
      )}
    </InstallAppButtonStyled>
  );
};

const InstallAppButtonStyled = styled.button`
  display: flex;
  font-family: 'SB Sans Text';
  font-size: 14px;
  background: #8D77F8;
  border-radius: 8px;
  padding: 10px 16px;
  width: 100%;
  border: none;
  color: white;
  display: flex;
  align-items: center
  margin-top: 16px;
  gap: 12px;
  cursor: pointer;

  @media (min-width: 1129px) {
    width: 280px;
  }

  &:hover {
    background: #A290F8;
  }
`;
