import React from "react";
import RootStore from "./rootStore";

export const storesContext = React.createContext({
  RootStore,
  CommonSettingsStore: RootStore.CommonSettingsStore,
  NotificationStore: RootStore.NotificationStore,
  PointStore: RootStore.PointStore,
  SoundStore: RootStore.SoundStore
});
