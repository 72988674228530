// @ts-nocheck
import React from "react";
import styled from "styled-components";
import {Artist, InfoWrapper, ItemBox, Time, Title} from "./playlist-parts";
import {Icon} from "../../ui";
import Skeleton from "react-loading-skeleton";
import {useDislike} from "../../hooks";
import {ITrack, ITrackList} from "../../stores/pointStore";

interface IHistoryProps {
  history: ITrackList
  isLoggedIn: boolean
  toggleAuthWindow: () => void
  pointId: number
  isMobile: boolean
}

export const History: React.FC<IHistoryProps> = ({
                                                   history,
                                                   isLoggedIn,
                                                   toggleAuthWindow,
                                                   pointId,
                                                   isMobile
                                                 }) => {
  const skeletons = [];
  for (let i = 0; i < 10; i++) {
    skeletons.push(
      <ItemBox key={i}>
        <Time>
          <Skeleton height={12} width={35}/>
        </Time>
        <div>
          <Title>
            <Skeleton height={12} width={128}/>
          </Title>
          <Artist>
            <Skeleton height={12} width={isMobile ? "150%" : 354}/>
          </Artist>
        </div>
      </ItemBox>
    );
  }

  return (
    <>
      {history
        ? history.sort((a:ITrack, b:ITrack) => a.id > b.id ? -1 : 1).map(item => (
          <HistoryItem
            key={item.id}
            pointId={pointId}
            isLoggedIn={isLoggedIn}
            toggleAuthWindow={toggleAuthWindow}
            item={item}
          />
        ))
        : [skeletons]}
    </>
  );
};

interface IHistoryItemProps {
  item: ITrack
  pointId: number
  isLoggedIn: boolean
  toggleAuthWindow: () => void
}

const HistoryItem: React.FC<IHistoryItemProps> = (
  {
    item,
    pointId,
    isLoggedIn,
    toggleAuthWindow
  }) => {
  const handleClick = useDislike({
    mediafileId: item.mediafile.id,
    pointId,
    isLoggedIn,
    toggleAuthWindow
  });

  return (
    <StyledItemBox key={item.id}>
      <Time>{ item.onAir ? new Date(item.onAir).toTimeString().slice(0, 5) : ''}</Time>
      <InfoWrapper>
        <Title title={item.mediafile.title}>{item.mediafile.title}</Title>
        <Artist title={item.mediafile.performerTitle}>
          {item.mediafile.performerTitle}
        </Artist>
      </InfoWrapper>
      {item.mediafile.type === 0 && (
        <IconBox onClick={handleClick}>
          <Icon name="thumbDown"/>
        </IconBox>
      )}
    </StyledItemBox>
  );
};

const IconBox = styled.div`
  display: none;
  color: ${props => props.theme.dislikeColor};

  &.active {
    color: ${props => props.theme.activeDislikeColor};
  }

  /* margin-left: auto; */
  position: absolute;
  right: 2.4rem;
  cursor: pointer;
`;

const StyledItemBox = styled(ItemBox)`
  position: relative;

  &:hover {
    ${IconBox} {
      display: block;
    }
  }

  @media (max-width: 1128px) {
    ${IconBox} {
      display: block;
    }
  }

  &:hover {
    background-color: ${props => props.theme.itemHoverColor};
  }
`;
