import { css } from "styled-components";

import SB_Sans_Text from "./SB_Sans_Text.ttf";

export const font_face_SB_Sans_Text = css`
  @font-face {
    font-family: "SB Sans Text";
    src: url(${SB_Sans_Text}) format("truetype");
  }
`;
