import {action, decorate, observable} from "mobx";
import {client} from "../apolloClient";
import {BP_AND_ROTATION, STATUS, SYNC_STATUS} from "../gql";
import {changeLogLevel, createLogger} from "../utils/logging";
import {RootStoreType} from "./rootStore";
import React from "react";

const logger = createLogger('@store/point');

interface IPointStatus {
  isAvailable?: boolean
  isEnabled?: boolean
  isExpired?: boolean
  isSleep?: boolean
  streamUrl?: string
  onlineMediafile?: {
    id: number
    title: string
    performerTitle: string
    type: number
    mediafileValue: {
      [key: number]: {
        id: number
        color: string
        title: string
      }
    }
  },
  trialEndsAt?: string | null,
  paymentState?: PaymentStateEnum,
  isBillingEnabled?: boolean,
}

export enum PaymentStateEnum {
  GRACE = 'GRACE',
  PAID = 'PAID',
  TRIAL = 'TRIAL',
  UNPAID = 'UNPAID',
  WAIT_PAYMENT = 'WAIT_PAYMENT',
}

export type ITrack = {
  id: number, playtime: string, onAir: string | null, mediafile: {
    title: string
    performerTitle: string
    type: number
    id: number
  }
};

export type ITrackList = ITrack[];

interface IPointPublic {
  address?: string
  comment?: string
  contentBlocks?: {
    id: number,
    endDate: string | null,
    startDate: string | null,
    values: {
      id: number
      color: string
      title: string
    }[],
  }[]
  endSleep?: string | null
  startSleep?: string | null
  id?: number
  streamTitle?: string
  streamUrl?: string
  userRegistered?: boolean
}

interface IPlayerDevice {
  additionalFeatures: string[]
  bindingCode: string
  debugMode: boolean
  logLevel: string
  point: {
    company: {
      country: string
    }
  }
}

interface IQueryData {
  pointPublicNearRotation: ITrackList
  pointPublic: IPointPublic
  playerDevice: IPlayerDevice
}

class PointStore {
  history: ITrackList | null = null;
  playlist: ITrackList | null = null;
  pointInfo: IPointPublic = {};
  pointStatus: IPointStatus = {};
  pointPresent: boolean = true;
  RootStore: RootStoreType;
  lastSubscriptionFetch: number | null = null;
  country: string | null = null;

  constructor(rootStore: RootStoreType) {
    this.RootStore = rootStore;
  }

  fetchRotation = () => {
    return client
      .query({
        query: BP_AND_ROTATION,
        fetchPolicy: "no-cache",
        variables: {linkName: this.RootStore.CommonSettingsStore.linkName},
        errorPolicy: 'all',
      })
      .then(({data, errors}) => {
        if (errors) {
          logger.error('Received error on fetchRotation query', {errors});
          const matches = errors
            .map(err => err.message)
            .filter(msg => msg.includes('Point matching query does not exist'))
          if (matches.length > 0) {
            this.pointPresent = false;
            this.handlePointDoesNotExist();
          }
        } else {
          logger.info('Received data on fetchRotation query', {data});
          this.handleQuery(data);
        }
      })
  };

  subscribe = (_checkSub: boolean = true) => {
    const _this = this;

    const checkSub = () => {
      client
        .subscribe({
          query: STATUS,
          variables: {linkName: _this.RootStore.CommonSettingsStore.linkName},
        })
        .subscribe({
            next({data}) {
              _this.lastSubscriptionFetch = Date.now();
              _this.fetchRotation().then(() => {
                if (data?.pointStatus?.streamUrl) {
                  _this.handleSubscription(data);
                }
              });
            },
          },
          () => setTimeout(() => {
            _this.lastSubscriptionFetch = null;
            _this.subscribe();
          }, 30 * 1000),
          () => setTimeout(() => {
            _this.lastSubscriptionFetch = null;
            _this.subscribe();
          }, 30 * 1000));
    }
    // First make a simple query to fetch first data
    logger.debug('Fetching first point status');
    client.query({
      query: SYNC_STATUS,
      variables: {linkName: this.RootStore.CommonSettingsStore.linkName},
    }).then(
      (data) => {
        logger.debug('Received point stat', {data});
        _this.fetchRotation()
          .then(() => {
            logger.debug('Received rotation');
            _this.handleSubscription({pointStatus: data.data.pointStatusPure});
            logger.debug('Launching subscription');

            if (!_this.lastSubscriptionFetch) {
              setTimeout(() => _this.subscribe(false), 30 * 1000);
            }

            if (_checkSub) checkSub();
          })
          .catch(() => setTimeout(() => {
            _this.lastSubscriptionFetch = null;
            _this.subscribe();
          }, 30 * 1000));
      }).catch((e) => setTimeout(() => {
      _this.lastSubscriptionFetch = null;
      _this.subscribe();
    }, 30 * 1000));
  };

  handleSubscription = ({pointStatus}: { pointStatus: IPointStatus }) => {
    const newPointStatus = {...pointStatus};
    logger.info('received subscription data', {status: newPointStatus});

    if (newPointStatus.isExpired) {
      this.RootStore.SoundStore.handleExpired();
      this.pointStatus = newPointStatus;
      return;
    }

    if (!newPointStatus.isEnabled) {
      this.RootStore.SoundStore.handleTurnedOff();
      this.pointStatus = newPointStatus;
      return;
    }

    if (newPointStatus.isSleep) {
      this.RootStore.SoundStore.handleSleep();
      this.pointStatus = newPointStatus;
      return;
    }

    if (!newPointStatus.streamUrl)
      newPointStatus.streamUrl = this.pointStatus.streamUrl;

    if (!newPointStatus.onlineMediafile) {
      // keep old data if onlineMediafile is missing
      newPointStatus.onlineMediafile = this.pointStatus.onlineMediafile;
    }

    if (newPointStatus.isAvailable) this.pointStatus = newPointStatus;
  };

  handlePointDoesNotExist = () => {
    this.pointPresent = false;
    if (
      this.RootStore.NotificationStore.show
      && typeof (this.RootStore.NotificationStore.text) === 'string'
      && this.RootStore.NotificationStore.text.includes('Ссылка некорректная')
    ) {
      logger.info('Error notification already present, do not open new one')
    } else {
      if (this.RootStore.CommonSettingsStore.linkName.length > 0) {
        this.RootStore.NotificationStore.showNotification({
          timeout: null,
          text: 'Ccылка некорректная. Обратитесь в техническую поддержку по номеру: 8 (800) 550-59-94 (доб.2)',
          type: "error",
          customButtons: [
            <span onClick={this.RootStore.NotificationStore.closeNotification}>
          <a href="mailto:support@muz-lab.ru">Написать</a>
            </span>,
            <span onClick={this.RootStore.NotificationStore.closeNotification}>
          <a href="tel:+88005505994">Позвонить</a>
            </span>,
          ],
        })
      }
    }
  }

  handlePointExists = () => {
    this.pointPresent = true;
    if (
      this.RootStore.NotificationStore.show
      && typeof (this.RootStore.NotificationStore.text) === 'string'
      && this.RootStore.NotificationStore.text.includes('Ссылка некорректная')
    ) {
      this.RootStore.NotificationStore.closeNotification();
    }
  }

  handleQuery = (data: IQueryData) => {
    this.pointPresent = true;
    if (!this.pointStatus.streamUrl) {
      this.pointStatus.streamUrl = data.pointPublic.streamUrl;
    }

    const source: ITrackList = data.pointPublicNearRotation;
    const history: ITrackList = [];
    const playlist: ITrackList = [];
    source.forEach((item) =>
      item.onAir ? history.push(item) : playlist.push(item)
    );
    this.history = history;
    this.playlist = playlist;
    this.pointInfo = data.pointPublic;
    this.country = data.playerDevice.point?.company?.country;

    const deviceData = data.playerDevice;
    changeLogLevel(deviceData.logLevel);
  };
}

decorate(PointStore, {
  history: observable,
  playlist: observable,
  pointInfo: observable,
  pointPresent: observable,
  pointStatus: observable,
  handleSubscription: action,
  handleQuery: action,
});

export default PointStore;
