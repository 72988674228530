import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MobilePlayer } from "./mobile-player";
import { Icon } from "../../ui";
import { useStores } from "../../hooks";
import {
  NetworkBlock,
  Scrollbar,
  Information,
  Playlist,
  History,
} from "../common";
import { observer } from "mobx-react";
import { Header } from "./header";
import { FullScreenBanner } from "../banner/FullScreenBanner";
import { ampli } from "../../ampli";

export const MobileTemplate = observer(() => {
  const {
    CommonSettingsStore: {
      isLoggedIn,
      toggleLoginPage,
      internetSpeed,
      windowHeight,
      toggleTheme,
      isDarkMode,
    },
    PointStore: { pointStatus, pointInfo, history, playlist },
  } = useStores();
  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (tab === 0 && pointInfo.id) {
      ampli.playerShown({
        point_id: pointInfo.id.toString(),
      });
    }

    if (tab === 3) {
      ampli.infoShown();
    }
  }, [pointInfo.id, tab]);

  return (
    <Wrapper>
      <div>
        <TopWrapper>
          <NetworkBlock speed={internetSpeed} />
          <ToogleIconBox onClick={toggleTheme}>
            <Icon
              iconColor={isDarkMode ? "#F5F5F5" : "#5c5c5c"}
              name={isDarkMode ? "dark-mode" : "light-mode"}
            />
          </ToogleIconBox>
        </TopWrapper>

        <Header tab={tab} />

        <Scrollbar height={`calc(${windowHeight}px - 10.4rem - 10.4rem)`}>
          {tab === 0 && (
            <MobilePlayer
              contentBlocks={pointInfo.contentBlocks}
              onlineMediafile={pointStatus.onlineMediafile}
              pointId={pointInfo.id}
              isLoggedIn={isLoggedIn}
              toggleAuthWindow={toggleLoginPage}
            />
          )}
          {tab === 1 && <Playlist playlist={playlist} isMobile={true} />}
          {tab === 2 && (
            <History
              toggleAuthWindow={toggleLoginPage}
              isLoggedIn={isLoggedIn}
              history={history}
              pointId={pointInfo.id}
              isMobile={true}
            />
          )}
          {tab === 3 && (
            <Information
              pointPublic={pointInfo}
              weatherStatus={pointStatus.weatherStatus}
              isLoggedIn={isLoggedIn}
              isMobile={true}
            />
          )}
        </Scrollbar>
      </div>

      <TabControls>
        <Tab className={tab === 0 && "active"} onClick={() => setTab(0)}>
          <Icon name="playCircle" />
        </Tab>
        <Tab className={tab === 1 && "active"} onClick={() => setTab(1)}>
          <Icon name="playlistPlay" />
        </Tab>
        <Tab className={tab === 2 && "active"} onClick={() => setTab(2)}>
          <Icon name="history" />
        </Tab>
        <Tab className={tab === 3 && "active"} onClick={() => setTab(3)}>
          <Icon name="info" />
        </Tab>
      </TabControls>
      <FullScreenBanner />
    </Wrapper>
  );
});

const TopWrapper = styled.div`
  margin: 2.4rem 0 0 2.4rem;
  display: flex;
`;

const Tab = styled.div`
  color: ${(props) => props.theme.tabIconColor};
  &.active {
    span {
      &:hover {
        opacity: 1;
      }
    }
    color: ${(props) => props.theme.activeTabIconColor};
  }
`;

const TabControls = styled.div`
  display: flex;
  height: 8rem;
  align-items: center;
  justify-content: space-around;
  border-top: 1px solid #ebebeb;
  margin-top: auto;
  padding: 0 1.5rem;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: ${(props) => props.theme.mobileBackground};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.mobileBackground};
`;

const ToogleIconBox = styled.div`
  cursor: pointer;
  margin-left: auto;
  margin-right: 2.4rem;
`;
