import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Root } from "./root";
import "simplebar/dist/simplebar.min.css";
import { ApolloProvider } from "@apollo/react-hooks";
import { GlobalStyles } from "./global-styles";
import { client } from "./apolloClient";
import { Updater } from "./components/updater";
import { rememberAppInitTime } from "./utils/metrics";

rememberAppInitTime();

ReactDOM.render(
  <ApolloProvider client={client}>
    <Updater>
      <Root />
      <GlobalStyles />
    </Updater>
  </ApolloProvider>,
  document.getElementById("root")
);
