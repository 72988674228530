import React, {useEffect} from "react";
import styled, {ThemeProvider} from "styled-components";
import {Header} from "./header";
import {DesktopPlayer} from "./player";
import {DesktopTabs} from "./desktop-tabs";
import {Information, Scrollbar} from "../common";
import {useStores} from "../../hooks";
import {observer} from "mobx-react";
import darkTheme from "../../themes/dark";
import {ampli} from "../../ampli";

export const DesktopFailover = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <Wrapper>
        <FailoverWrapper>
          <Header/>
          <FailoverContainer>
            <FailoverHeader>
              Упс, что-то пошло не так!
            </FailoverHeader>
            <Separator/>
            Обратитесь, пожалуйста, в техническую поддержку<br/>
            по телефону: 8 (800) 550-59-94 (доб. 2)
          </FailoverContainer>
        </FailoverWrapper>
      </Wrapper>
    </ThemeProvider>
  );
}

const FailoverWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FailoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: calc(100vh - 10rem);
  width: 100vw;

  font-family: "PT Sans", sans-serif;
  font-style: normal;
  font-size: 2.2rem;
  font-weight: bolder;
  color: #BDBDBD;
  text-align: center;

  line-height: 2.4rem;
`;

const FailoverHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 5.6rem;
  line-height: 5.4rem;
  color: #F5F5F5;
`;

const Separator = styled.div`
  height: 8rem;
`;

export const DesktopTemplate = observer(() => {
  const {
    CommonSettingsStore: {isLoggedIn, toggleLoginPage, isMobile},
    PointStore: {pointStatus, pointInfo, history, playlist},
  } = useStores();

  useEffect(() => {
    if (!pointInfo.id) return;

    ampli.playerShown({
      point_id: pointInfo.id.toString(),
    });
  }, [pointInfo.id]);

  return (
    <Wrapper>
      <Header/>
      <DesktopPlayer
        onlineMediafile={pointStatus.onlineMediafile}
        pointId={pointInfo.id}
        isLoggedIn={isLoggedIn}
        toggleAuthWindow={toggleLoginPage}
      />
      <Scrollbar height="calc(100vh - 15.6rem)">
        <Container>
          <Information
            weatherStatus={{}}
            pointPublic={pointInfo}
            isMobile={isMobile}
          />
          <DesktopTabs
            history={history}
            playlist={playlist}
            isLoggedIn={isLoggedIn}
            toggleAuthWindow={toggleLoginPage}
            pointId={pointInfo.id}
          />
        </Container>
      </Scrollbar>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  max-height: 100vh;
  min-height: 100vh;
  background: ${(props) => props.theme.background};
`;

const Container = styled.div`
  display: flex;
  padding-top: 2.4rem;
  justify-content: center;
  align-items: flex-start;
`;
