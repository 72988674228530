import React from "react";
import { PlayerControlButtons } from "./player-control-buttons";
import { PlayerTrackInfo } from "./player-track-info";
import { PlayerVolume } from "./player-volume";
import styled from "styled-components";
import { useStores } from "../../../hooks";
import { observer } from "mobx-react";

export const DesktopPlayer = observer(
  ({ onlineMediafile, pointId, isLoggedIn, toggleAuthWindow }) => {
    const {
      SoundStore: {
        SMObject,
        togglePlay,
        playing,
        disabled,
        volume,
        handleVolume,
        buffering,
      },
      PointStore: {
        pointPresent
      }
    } = useStores();

    return (
      <PlayerBox>
        <PlayerContentBox>
          <PlayerTrackInfo
            mediafile={onlineMediafile}
            pointId={pointId}
            isLoggedIn={isLoggedIn}
            toggleAuthWindow={toggleAuthWindow}
          />

          <PlayerControlButtons
            handleClick={togglePlay}
            playing={playing}
            buffering={buffering}
            disabled={disabled || !SMObject || !pointPresent}
          />
          <PlayerVolume
            hasSound={!!SMObject}
            volume={[volume]}
            handleVolume={handleVolume}
          />
        </PlayerContentBox>
      </PlayerBox>
    );
  }
);

const PlayerBox = styled.div`
  width: 100%;
  height: 9.2rem;
  position: fixed;
  z-index: 900;
  bottom: 0;
  background-color: ${(props) => props.theme.desktopPlayerBg};
  transition: 300ms transform ease;
  transform: translate(0, 0%);
  box-shadow: 4px 0px 30px rgba(0, 0, 0, 0.07);
`;

const PlayerContentBox = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12.8rem;
  > div {
    flex: 1 1 0%;
  }
`;
