import React from "react";
import styled from "styled-components";
import { H5, ContentMedium, Icon } from "../../../ui";
import Skeleton from "react-loading-skeleton";
import { useDislike } from "../../../hooks";

export const PlayerTrackInfo = ({
  pointId,
  isLoggedIn,
  toggleAuthWindow,
  mediafile = {},
}) => {
  const handleClick = useDislike({
    mediafileId: mediafile && mediafile.id,
    pointId,
    isLoggedIn,
    toggleAuthWindow,
  });

  return (
    <TrackInfo>
      {mediafile?.type === 0 && (
        <IconBox onClick={handleClick}>
          <Icon name="thumbDown" />
        </IconBox>
      )}
      <div>
        <TrackName>
          {(mediafile && mediafile.title) || (
            <Skeleton height={12} width={128} />
          )}
        </TrackName>
        <Artist>
          {(mediafile && mediafile.performerTitle) || (
            <Skeleton height={12} width={288} />
          )}
        </Artist>
      </div>
    </TrackInfo>
  );
};

const TrackInfo = styled.div`
  display: flex;
`;

const TrackName = styled(H5)`
  margin: 0;
  color: ${(props) => props.theme.headingsColor};
`;

const Artist = styled(ContentMedium)`
  margin: 0;
  color: ${(props) => props.theme.textColor};
`;

const IconBox = styled.div`
  margin-right: 2.4rem;
  color: ${(props) => props.theme.dislikeColor};
  &.active {
    color: ${(props) => props.theme.activeDislikeColor};
  }
  cursor: pointer;
`;
