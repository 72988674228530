import React from "react";
import {ContentLarge, Icon} from "../../../ui";
import {IconWrapper, Section, StyledH4} from "./common";
import {useStores} from "../../../hooks";
import styled from "styled-components";

const LinkFeedback = () => {
  const {
    PointStore: {pointInfo},
  } = useStores();

  const feedbackUrl = `https://forms.zohopublic.com/zvukbusiness/form/Untitled/formperma/-XBk8HJcOlcBVK7F-T2GzzpJJcoptfKT4bhqt0b-1eI?point_id=${pointInfo.id}`;

  return (<div>
    <StyledLink>
      <a href={feedbackUrl} target="_blank" rel="noopener noreferrer">
        Оценить
      </a>
    </StyledLink>
  </div>)
}

export const Feedback = ({inline}) => {
  const text = "Как вам музыка?"

  return (<>
    {inline ?
      <InlineContainer>
        <InlineTitle>
          {text}
        </InlineTitle>
        <LinkFeedback />
      </InlineContainer>
      : <Section>
        <StyledContainer>
          <StyledIconWrapper>
            <Icon name="star" iconColor="#FFF9DD"/>
          </StyledIconWrapper>
          <StyledTextWrapper>
            <StyledH4>
              {text}
            </StyledH4>
            <LinkFeedback />
          </StyledTextWrapper>
        </StyledContainer>
      </Section>}
  </>);
};

const InlineContainer = styled.div`
  display: flex;
`;

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
`;


const StyledIconWrapper = styled(IconWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  padding-top: 3px;
  background-color: #FFA726;
`;

const StyledTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledLink = styled(ContentLarge)`
  color: #15BF95;
  margin-left: 0.5rem;
`;


const InlineTitle = styled(ContentLarge)`
  color: ${(props) => props.theme.textColor};
`;
