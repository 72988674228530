import React, {useEffect} from "react";
import {useStores} from "./hooks";
import {DesktopTemplate, Login, MobileTemplate} from "./components";
import {observer} from "mobx-react";
import {DesktopFailover} from "./components/desktop/desktop-template";
import {initAmpli} from "./utils/initAmpli";
import {ampli} from "./ampli";

export const App = observer(() => {
  const {
    CommonSettingsStore: {showLoginPage, isMobile, linkName},
    PointStore: {pointInfo},
  } = useStores();

  useEffect(() => {
    if (pointInfo.id) {
      initAmpli()
      ampli.identify(undefined, { point_id: String(pointInfo.id) })
    }
  }, [pointInfo.id])

  if (linkName.length === 0) return <DesktopFailover/>;
  if (showLoginPage) return <Login/>;
  if (isMobile) return <MobileTemplate/>;
  return <DesktopTemplate/>;
});
