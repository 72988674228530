import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import { Icon } from "../";
import { InputWithFocus as Input } from "./input";

export const SearchInput = ({
  value,
  onChange,
  placeholder,
  resetValue,
  id,
  type,
  autoFocus
}) => (
  <Wrapper>
    <SearchIconBox>
      <Icon iconColor="var(--black-lt-1)" name="search" />
    </SearchIconBox>
    <StyledInput
      hasValue={!!value}
      value={value}
      autoComplete="off"
      placeholder={placeholder}
      onChange={onChange}
      id={id}
      autoFocus={autoFocus}
      type={type}
    />
    {value && (
      <CloseIconBox onClick={resetValue}>
        <Icon iconColor="var(--black-lt-1)" name="close" />
      </CloseIconBox>
    )}
  </Wrapper>
);

SearchInput.defaultProps = {
  type: "primary"
};

SearchInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  resetValue: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["primary", "secondary"]),
  autoFocus: PropTypes.bool
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledInput = styled(Input)`
  padding: 0.6rem 1.2rem 0.6rem 4rem;
  border-color: ${props =>
    props.hasValue ? "var(--accent-one)" : "var(--black-lt-5)"};

  ${({ type, hasValue }) => {
    if (type === "secondary") {
      return css`
        background-color: var(--white);
      `;
    } else {
      return css`
        background-color: ${({ hasValue }) =>
          hasValue ? "var(--white)" : "var(--black-lt-5)"};
      `;
    }
  }}
`;

const SearchIconBox = styled.div`
  display: flex;
  position: absolute;
  /* top: 0.5rem; */
  left: 0.8rem;
`;

const CloseIconBox = styled.div`
  display: flex;
  position: absolute;
  /* top: 0.5rem; */
  right: 0.8rem;
  cursor: pointer;
  z-index: 10;
`;
