import {onError} from "apollo-link-error";
import {WebSocketLink} from "apollo-link-ws";
import {split} from "apollo-link";
import {HttpLink} from "apollo-link-http";
import {InMemoryCache} from "apollo-cache-inmemory";
import {getMainDefinition} from "apollo-utilities";
import {setContext} from "apollo-link-context";
import {ApolloClient} from "apollo-client";
import {getLinkName} from "./stores/commonSettingsStore";

const wssUri = (window as any)._env_.WS_DOMAIN
  ? (window as any)._env_.WS_DOMAIN
  : "wss://tech.muz-lab.ru/api/v1/subscriptions/";

const httpLink = new HttpLink({
  uri: "/api/v1/graphql/",
});

const authLink = setContext((_, {headers}) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("authToken");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
      'client-type': 0,
      'client-uuid': getLinkName(),
    },
  };
});

const wsLink = new WebSocketLink({
  uri: wssUri,
  options: {
    reconnect: true,
  },
});

const errorLink = onError(
  ({graphQLErrors, networkError, operation, forward}) => {
    if (graphQLErrors) {
      // User access token has expired we remove outdated token & refresh page (for now)
      if (graphQLErrors[0].message === "Signature has expired") {
        localStorage.removeItem("authToken");
        window.location.reload();
      }
    }
  }
);

export const client = new ApolloClient({
  link: errorLink.concat(
    authLink.concat(
      split(
        // split based on operation type
        ({query}) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
          );
        },
        wsLink,
        httpLink
      )
    )
  ),
  cache: new InMemoryCache(),
});
