import React from "react";
import { action, autorun, decorate, observable, reaction } from "mobx";
// @ts-ignore
import { soundManager } from "soundmanager2";
import { client } from "../apolloClient";
import { UPDATE_STATUS } from "../gql";
import { RootStoreType } from "./rootStore";
import { logPlayerReadyTime } from "../utils/metrics";
import { MobileStabilityTip } from '../components/mobile/mobile-stability-tip';
import { ampli } from "../ampli";

class SoundStore {
  RootStore: RootStoreType;
  SMObject: any | null = null;
  playing = false;
  disabled = false;
  volume = 50;
  shouldPlayAfterSleep = false;
  intervalId?: number | null = null;
  buffering = false;

  constructor(rootStore: RootStoreType) {
    this.RootStore = rootStore;

    autorun(() => {
      // turns on sound after point goes online after sleep
      if (!this.RootStore.PointStore.pointStatus.isSleep) {
        if (this.shouldPlayAfterSleep) {
          this.shouldPlayAfterSleep = false;
          this.SMObject?.play();
          this.disabled = false;
          this.playing = true;
          this.RootStore.NotificationStore.closeNotification();

          this._submitStatus("playing");
        }
      }
    });

    // set up sound
    reaction(
      () => this.RootStore.PointStore.pointStatus.streamUrl,
      this._handleNewURL
      // { fireImmediately: true }
    );

    autorun(() => {
      // set up watcher
      const {
        isSleep,
        isAvailable,
        isEnabled,
      } = this.RootStore.PointStore.pointStatus;

      if (this.playing && !isSleep && isAvailable && isEnabled) {
        if (!this.intervalId) this._checkIsPlaying();
      } else {
        if (this.intervalId) {
          clearInterval(this.intervalId);
          this.intervalId = null;
        }
      }
    });
  }

  showAutoSleep = (endSleep: string) => {
    this.shouldPlayAfterSleep = true;
    this.RootStore.NotificationStore.showNotification({
      timeout: null,
      text: `Вещание автоматически восстановится с ${endSleep}.`,
    });
  };

  handleSleep = () => {
    // if already sleeping
    if (this.shouldPlayAfterSleep) return;

    let {startSleep, endSleep} = this.RootStore.PointStore.pointInfo;
    if (!startSleep || !endSleep) {
      // subscription may come faster than query => need to wait it
      setTimeout(() => {
        this.watchForErrors();
      }, 1000);
      return;
    }
    startSleep = startSleep.slice(0, 5);
    endSleep = endSleep.slice(0, 5);
    this.shouldPlayAfterSleep = false;
    this.disabled = true;

    if (this.playing) {
      this.SMObject?.stop();
      this.showAutoSleep(endSleep);
    } else {
      this.RootStore.NotificationStore.showNotification({
        timeout: null,
        text: (
          <>
            Вещание приостановлено с {startSleep} до {endSleep}. Чтобы
            автоматически восстановить вещания после паузы, нажмите
            <b> Запустить после паузы.</b>
          </>
        ),
        customButtons: [
          <span onClick={() => this.showAutoSleep(endSleep ?? '')}>
            Запустить <br/> после паузы
          </span>,
          <span
            onClick={() => {
              this.disabled = false;
              this.playing = false;
              this.RootStore.NotificationStore.closeNotification();
            }}
          >
            Игнорировать
          </span>,
        ],
      });
    }
  };

  disablePlayer = () => {
    if (this.SMObject) this.SMObject?.stop();
    this.playing = false;
    this.disabled = true;
  };

  handleTurnedOff = () => {
    this.disablePlayer();

    this.RootStore.NotificationStore.showNotification({
      timeout: null,
      text: "Вещание остановлено. Обратитесь в техническую поддержку.",
      customButtons: [
        <span onClick={this.RootStore.NotificationStore.closeNotification}>
          <a href="mailto:support@muz-lab.ru">Написать</a>
        </span>,
        <span onClick={this.RootStore.NotificationStore.closeNotification}>
          <a href="tel:+88005505994">Позвонить</a>
        </span>,
      ],
    });
  };

  handleExpired = () => {
    this.disablePlayer();

    this.RootStore.NotificationStore.showNotification({
      timeout: null,
      text: (
        <>
          Тестовый период завершен. <br/>
          Обратитесь к нам по тел. 8 800 550 5994
        </>
      ),
      customButtons: [
        <span onClick={this.RootStore.NotificationStore.closeNotification}>
          <a href="mailto:support@muz-lab.ru">Написать</a>
        </span>,
        <span onClick={this.RootStore.NotificationStore.closeNotification}>
          <a href="tel:+88005505994">Позвонить</a>
        </span>,
      ],
    });
  };

  handleTurningOn = () => {
    this.SMObject.stop();
    this.RootStore.NotificationStore.showNotification({
      text: "Запускается. Это займет меньше минуты.",
      timeout: true,
    });
    setTimeout(() => {
      this.SMObject.play();
      this._submitStatus("playing");
    }, 5000);
  };

  watchForErrors = () => {
    const {
      isSleep,
      isAvailable,
      isEnabled,
      isExpired,
    } = this.RootStore.PointStore.pointStatus;
    if (isExpired) {
      this.handleExpired();
    } else if (isSleep) {
      this.handleSleep();
    } else if (!isEnabled) {
      this.handleTurnedOff();
    } else if (isEnabled && !isAvailable) {
      this.handleTurningOn();
    }
    this._submitStatus("not_playing");
  };

  handleBuffer = (status: 0 | 1) => {
    // 1 - buffering, 0 - not
    if (status === 0) {
      this.playing = true;
      this.buffering = false;
    } else {
      this.buffering = true;
    }
    // this.buffering = status === 1;
  };

  init = ({shouldPlay}: { shouldPlay: boolean }) => {
    const sound = soundManager.createSound({
      url: this.RootStore.PointStore.pointStatus.streamUrl,
      autoLoad: false,
      multiShot: false,
      multiShotEvents: false,
      // bufferTime: 60,
      volume: this.volume,
      onerror: this._tryToPlay,
      onfinish: this._tryToPlay,
      onload: this.watchForErrors,
      onstop: () => this.SMObject.unload(),
      onbufferchange: this.handleBuffer,
    });
    this.shouldPlayAfterSleep = false;
    this.disabled = false;
    if (shouldPlay) {
      sound.play();
      this._submitStatus("playing");
    }
    this.SMObject = sound;
  };

  togglePlay = () => {
    const isMobile = this.RootStore.CommonSettingsStore.isMobile;
    const pointId = this.RootStore.PointStore.pointInfo.id;

    if (isMobile) {
      if (this.playing) {
        this.SMObject.stop();
      } else {
        this.SMObject.play();

        this.RootStore.NotificationStore.showNotification({
          type: 'tip',
          text: <MobileStabilityTip/>,
          timeout: null,
        });

        if (pointId) {
          ampli.playPressed({
            point_id: pointId.toString(),
          });
        }
      }
      this.playing = !this.playing;
    } else {
      this.RootStore.NotificationStore.showNotification({
        text: `${this.playing ? "Остановить" : "Начать"} вещание на этом устройстве?`,
        timeout: null,
        customButtons: [
          <span
            onClick={() => {
              if (this.playing) {
                this.SMObject.stop();
                this.shouldPlayAfterSleep = false; // reset for sure
                this._submitStatus("not_playing");
              } else {
                this.SMObject.play();
                this._submitStatus("playing");

                if (pointId) {
                  ampli.playPressed({
                    point_id: pointId.toString(),
                  });
                }
              }
              this.playing = !this.playing;
              this.RootStore.NotificationStore.closeNotification();
            }}
          >
            Да
          </span>,
          <span onClick={this.RootStore.NotificationStore.closeNotification}>
            Нет
          </span>,
        ],
      });
    }
  };

  handleVolume = (volume: any) => {
    this.volume = volume[0];
    this.SMObject.setVolume(volume[0]);
  };

  _submitStatus = (status: string) => {
    const pointLinkName = this.RootStore.CommonSettingsStore.linkName;
    client.mutate({
      mutation: UPDATE_STATUS,
      variables: {
        deviceName: window.navigator.userAgent,
        status,
        pointLinkName,
      },
    });
  };

  _setupStatusInterval = () => {
    setInterval(
      () => {
        let status = this.SMObject && this.SMObject.playState && !this.SMObject.paused ? "playing" : "not_playing";
        this._submitStatus(status);
      },
      30000
    );
  };

  _tryToPlay = () => {
    const {
      isSleep,
      isAvailable,
      isEnabled,
    } = this.RootStore.PointStore.pointStatus;

    setTimeout(() => {
      if (isEnabled && isAvailable && !isSleep) {
        this.SMObject.destruct();
        this.init({shouldPlay: true});
      }
    }, 1000);
  };

  _checkIsPlaying = () => {
    let lastPosition = 0;
    let errorsCount = 0;
    this.intervalId = window.setInterval(() => {
      if (this.buffering) return;
      const newPosition = this.SMObject.position;
      if (lastPosition === newPosition) {
        errorsCount += 1;
      }
      if (errorsCount > 5) {
        console.error("should have played");
        this._tryToPlay();
        errorsCount = 0;
      }
      lastPosition = newPosition;
    }, 1000);
  };

  _handleNewURL = () => {
    const {
      isAvailable,
      isEnabled,
      isExpired,
      streamUrl,
    } = this.RootStore.PointStore.pointStatus;

    if (isExpired || !isEnabled) return;

    if (!this.SMObject) {
      // if its first start
      this.init({shouldPlay: false});
      this._setupStatusInterval();
      logPlayerReadyTime();
    } else if (streamUrl !== "bad_url" && isAvailable) {
      // if its new url for current point
      this.SMObject.destruct();
      this.init({shouldPlay: this.playing}); // shouldPlay depends on current play status
    }
  };
}

decorate(SoundStore, {
  SMObject: observable,
  buffering: observable,
  playing: observable,
  disabled: observable,
  volume: observable,
  shouldPlayAfterSleep: observable,
  init: action,
  togglePlay: action,
  handleSleep: action,
  // handleError: action,
  _tryToPlay: action,
  _checkIsPlaying: action,
});

export default SoundStore;
