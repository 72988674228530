import React, { useState } from "react";
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import { Button, H3, Icon, ContentLargeBold } from "../../ui";

import { LoginEmail } from './email';
import { LoginPhone } from './phone';
import { useStores } from "../../hooks";

export const Login = observer(() => {
  const [isPhoneAuth, setIsPhoneAuth] = useState(false);

  const {
    CommonSettingsStore: { toggleLoginPage },
  } = useStores();

  return (
    <Container>
      <AuthWrap>
        <CloseBox>
          <div onClick={toggleLoginPage}>
            <Icon name="arrow-left" size="3.2" iconColor={"#5C5C5C"}/>
            <ContentLargeBold style={{color: "#5C5C5C"}}>Вернуться к плееру</ContentLargeBold>
          </div>
        </CloseBox>

        <H3 style={{color: "#5C5C5C"}}>Авторизация</H3>

        {isPhoneAuth ? (
          <LoginPhone />
        ) : (
          <LoginEmail />
        )}

        <BtnChangeMode
          type="secondary"
          onClick={() => setIsPhoneAuth(!isPhoneAuth)}
        >
          {isPhoneAuth ? 'Войти с помощью электронной почты' : 'Войти с помощью номера телефона'}
        </BtnChangeMode>
      </AuthWrap>
    </Container>
  );
});

const BtnChangeMode = styled(Button)`
    margin-top: 1.0rem;
`;


const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: #f5f5f5;

  height: 100vh;
  width: 100vw;

  @media screen and (orientation: portrait) and (max-width: 375px) {
    align-items: flex-start;
  }

  @media screen and (orientation: landscape) and (max-height: 30rem) {
    height: auto;
  }
`;

const AuthWrap = styled.div`
  width: 37.5rem;

  padding: 6.4rem 3.2rem;

  > h3:first-child {
    color: #5c5c5c;
    margin-bottom: 3.4rem;
  }

  @media screen and (min-width: 376px) {
    border-radius: 4px;

    ${({ transparent }) =>
  !transparent &&
  css`
        background: #fff;
      `};
  }
`;

const CloseBox = styled.span`
  top: 0;
  position: absolute;
  left: 0;
  height: 6.4rem;
  padding-left: 2.8rem;
  display: flex;
  align-items: center;

  ${ContentLargeBold} {
    margin-left: 1.2rem;
  }
  > div {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
`;
