import React, { useState, useRef } from "react";
import { Input } from "./index";
import styled from "styled-components";
import { Icon } from "../";

export const PasswordInput = ({
  value,
  onChange,
  hasError,
  placeholder,
  onFocus,
  onBlur
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputRef = useRef(null);

  const toggleInput = e => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const _onFocus = ev => {
    // hack to prevent browser's autocomplete functionality
    if (inputRef.current) {
      ev.target.readOnly = false;
    }

    onFocus && onFocus(ev);
  };

  return (
    <Wrapper>
      <Input
        ref={inputRef}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type={!showPassword ? "password" : "text"}
        hasError={hasError}
        onFocus={_onFocus}
        onBlur={onBlur}
        autoComplete="off"
        readOnly
      />
      <IconBox onClick={e => toggleInput(e)}>
        <Icon name="eye" iconColor={showPassword ? "#000" : "#5C5C5C"} />
      </IconBox>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

const IconBox = styled.div`
  margin-left: auto;
  position: absolute;
  right: 0.8rem;
`;
