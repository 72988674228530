import React from "react";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";

export const Spinner = ({ scale, color }) => (
  <SpinnerBox scale={scale} viewBox="0 0 50 50">
    <Circle cx="25" cy="25" r="20" fill="none" color={color} />
  </SpinnerBox>
);

Spinner.propTypes = {
  scale: PropTypes.number,
  color: PropTypes.string
};

Spinner.defaultProps = {
  scale: 0.4
};

const rotate = scale => keyframes`
0% {
  transform: rotate(0) scale(${scale});
}
100% {
  transform: rotate(360deg) scale(${scale});
}
`;

const dash = keyframes`
0% {
  stroke-dasharray: 1, 150;
  stroke-dashoffset: 0;
}
50% {
  stroke-dasharray: 90, 150;
  stroke-dashoffset: -35;
}
100% {
  stroke-dasharray: 90, 150;
  stroke-dashoffset: -124;
} `;

const SpinnerBox = styled.svg`
  animation: ${props => rotate(props.scale)} 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;
  width: 40px;
  height: 40px;
`;

const Circle = styled.circle`
  stroke: ${({ color }) => color || "var(--accent-one)"};
  stroke-width: 4;
  stroke-linecap: round;
  animation: ${dash} 1.5s ease-in-out infinite;
`;
