import { decorate, observable, action } from "mobx";
import {RootStoreType} from "./rootStore";

interface IShowNotificationProps {
  type?: 'message' | 'error' | 'click' | 'tip'
  text: string | JSX.Element
  clickableAreaProps?: boolean | null
  timeout?: boolean | null
  customButtons?: JSX.Element[] | null
  closeCallback?: () => void
}

class NotificationStore {
  rootStore: RootStoreType
  constructor(rootStore: RootStoreType) {
    this.rootStore = rootStore;
  }

  type = ""; // message || error || click
  text: string | JSX.Element = "";
  show = false;
  clickableAreaProps: boolean | null = null;
  timeout: boolean | null = true; // if not null will be closed after 10s
  customButtons?: JSX.Element[] | null = null; // array with span`s and handlers only
  closeCallback = () => {};

  showNotification = ({
    type = "message",
    text = "",
    clickableAreaProps = null,
    timeout = true,
    customButtons = null,
    closeCallback = () => {}
  }: IShowNotificationProps) => {
    this.type = type;
    this.text = text;
    this.show = true;
    this.clickableAreaProps = clickableAreaProps;
    this.timeout = timeout;
    this.customButtons = customButtons;
    this.closeCallback = closeCallback;
    if (type !== "click" && timeout !== null) {
      setTimeout(this.closeNotification, 10000);
    }
  };

  closeNotification = () => {
    this.show = false;
  };
}

decorate(NotificationStore, {
  type: observable,
  text: observable,
  show: observable,
  closeCallback: observable,
  clickableAreaProps: observable,
  timeout: observable,
  customButtons: observable,
  showNotification: action,
  closeNotification: action
});

// NotificationStore.PropTypes = {};

export default NotificationStore;
