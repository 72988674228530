// @ts-nocheck
import React, { useEffect } from "react";
import { App } from "./App";
import { ThemeProvider } from "styled-components";
import mainTheme from "./themes/main.js";
import darkTheme from "./themes/dark.js";
import { SkeletonTheme } from "react-loading-skeleton";
import { useStores } from "./hooks";
import { Notification } from "./components";
import { observer } from "mobx-react";

export const Root = observer(() => {
  const {
    CommonSettingsStore: {
      isDarkMode,
      windowWidth,
      setWidth,
      setHeight,
      setInternetWatcher,
      handleOffline,
    },
    PointStore: { fetchRotation, subscribe },
  } = useStores();

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
      window.removeEventListener("offline", handleOffline);
    }
  }, [handleOffline, setHeight, setWidth, windowWidth]);

  useEffect(() => {
    fetchRotation();
    subscribe();
    setInternetWatcher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const color = isDarkMode ? "#5C5C5C" : "#E0E0E0";

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : mainTheme}>
      <SkeletonTheme
        color={color}
        highlightColor={color}
      >
        <App />
        <Notification />
      </SkeletonTheme>
    </ThemeProvider>
  );
});
