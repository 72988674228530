import styled from "styled-components";

export const TagNameSmall = styled.p`
  font-family: "PT Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.2rem;
  margin: 0;
  text-transform: lowercase;
`;
