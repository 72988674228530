import { gql } from "apollo-boost";

export const TOKEN_AUTH = gql`
  mutation($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      token
    }
  }
`;

export const CODE_CREATE = gql`
  mutation ($input: AuthorizationCodeCreateMutationInput!) {
    authorizationCodeCreate(input: $input) {
      ok
      errors {
        messages
      }
      codeData {
        isActive
        phone
        created
      }
    }
  }
`;


export const CODE_TOKEN_AUTH = gql`
  mutation authCodeToken ($input: JwtTokenAuthWithCodePureMutationInput!) {
    jwtTokenAuthWithCode(input: $input) {
      ok
      errors {
        messages
      }
      token {
        value
      }
    }
  }
`;
