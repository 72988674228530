import styled from "styled-components";
import { H5, H4 } from "../../../ui";

export const Section = styled.div`
  background: ${props => props.theme.infoBackground};
  border-radius: 8px;
  padding: 2.6rem 3.2rem;
  margin-bottom: 2.4rem;
  width: 100%;

  &:last-child {
    padding-bottom: 2.4rem;
  }

  @media (max-width: 1128px) {
    background-color: ${props => props.theme.mobileInfoBackground};
  }
`;

export const Content = styled.div`
  display: flex;
`;

export const IconWrapper = styled.div`
  color: ${props => props.theme.iconColor};
  span {
    &:hover {
      opacity: 1;
    }
  }
`;

export const StyledH5 = styled(H5)`
  color: ${props => props.theme.headingsColor};
  display: inline-block;
  margin-left: 1.6rem;
  width: 100%;
`;

export const StyledH4 = styled(H4)`
  display: inline-block;
  width: 100%;
  margin-left: 1.6rem;
  color: ${props => props.theme.headingsColor};
  font-weight: bold;
`;
