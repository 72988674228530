import React, { useState, useMemo, useEffect } from 'react';
import { observer } from "mobx-react";
import { useMutation } from '@apollo/react-hooks';
import styled from "styled-components";
import parsePhoneNumber, { AsYouType } from 'libphonenumber-js';

import { Button } from "../../ui";
import { useStores } from "../../hooks";
import { InputTooltipWithLabel } from "./input";
import { CODE_CREATE, CODE_TOKEN_AUTH } from './gql';

const checkPhoneValid = (phone) => {
  const parsedPhone = parsePhoneNumber(phone, { defaultCountry: 'RU' });
  if (parsedPhone) {
    return parsedPhone.isValid();
  }

  return false;
}

const beautifyPhone = (phone) => {
  return new AsYouType('RU').input(phone || '') || phone;
}

const preparePhone = (phone) => {
  const parsedPhone = parsePhoneNumber(phone, { defaultCountry: 'RU' });
  if (parsedPhone) {
    return parsedPhone.number;
  }

  return phone;
}

const useCountDown = () => {
  const [from, setFrom] = useState(0);
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    setCountdown(from);

    if (from === 0) return;

    const intervalId = setInterval(() => {
      setCountdown((curr) => {
        if (curr === 0) {
          setFrom(0);
          clearInterval(intervalId);
          return 0;
        }

        return curr - 1;
      });
    }, 900);

    return () => clearInterval(intervalId);
  }, [from]);

  return [countdown, setFrom];
}

export const LoginPhone = observer(() => {
  const [isCodeInputStep, setIsCodeInputStep] = useState(false);

  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");

  const isValid = useMemo(() => checkPhoneValid(phone), [phone]);
  const beautyPhone = useMemo(() => beautifyPhone(phone), [phone]);

  const [countdown, setCountdown] = useCountDown();

  const {
    NotificationStore: { showNotification },
    CommonSettingsStore: { handleLogin, toggleLoginPage },
  } = useStores();

  const [sendCode, { loading: loadingCode }] = useMutation(CODE_CREATE);

  const handlePhoneSubmit = (e) => {
    e.preventDefault();

    sendCode({
      variables: {
        input: { phone: preparePhone(phone) },
      }
    })
      .then(({ data }) => {
        if (!data.authorizationCodeCreate.codeData) {
          throw new Error();
        }

        setCountdown(60);
        setIsCodeInputStep(true);
      })
      .catch(() => {
        showNotification({
          type: "error",
          text: "Номер не зарегистрирован",
        });
      });
  };

  const [tokenCodeAuth, { loading: loadingToken }] = useMutation(CODE_TOKEN_AUTH);

  const handleAuthSubmit = (e) => {
    e.preventDefault();

    tokenCodeAuth({
      variables: {
        input: { username: preparePhone(phone), code },
      }
    })
      .then(({ data }) => {
        localStorage.setItem("authToken", data.jwtTokenAuthWithCode.token.value);
        toggleLoginPage();
        handleLogin();
      })
      .catch((e) => {
        showNotification({
          type: "error",
          text: "Код введён неверно",
        });
      });
  };

  const handleSubmit = (e) => {
    if (isCodeInputStep) {
      handleAuthSubmit(e)
    } else {
      handlePhoneSubmit(e)
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Group>
        {isCodeInputStep ? (
          <InputTooltipWithLabel
            label="Код"
            type="text"
            name="code"
            placeholder="1234"
            change={setCode}
            value={code}
          />
        ) : (
          <InputTooltipWithLabel
            label="Номер телефона"
            type="text"
            name="phone"
            placeholder="+7 999 000 00 00"
            change={setPhone}
            value={beautyPhone}
          />
        )}
      </Group>

      {isCodeInputStep && (
        <Btn
          type="primary"
          disabled={!code}
        >
          Войти
        </Btn>
      )}

      {isCodeInputStep && (
        <Btn
          type="secondary"
          disabled={loadingCode || countdown !== 0}
          onClick={handlePhoneSubmit}
        >
          {countdown ? `Отправить код ещё раз через ${countdown} сек.` : 'Отправить код ещё раз'}
        </Btn>
      )}

      {!isCodeInputStep && (
        <Btn
          type="primary"
          disabled={loadingCode || !isValid}
        >
          Отправить код
        </Btn>
      )}
    </Form>
  );
});

const Btn = styled(Button)`
  margin-top: 1.0rem;
  margin-bottom: 1.0rem;
`;

const Form = styled.form`
  margin-top: 4rem;
  margin-bottom: 2.2rem;
  width: 100%;
`;

const Group = styled.div`
  display: flex;
  align-items: center;
  padding: 2.6rem 0;
`;
