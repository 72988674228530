import {gql} from "apollo-boost";

export const BP_AND_ROTATION = gql`
  query($linkName: String) {
    pointPublic(linkName: $linkName) {
      id
      comment
      streamTitle
      startSleep
      endSleep
      userRegistered
      address
      streamUrl
      contentBlocks {
        id
        startDate
        endDate
        startDayTime
        endDayTime
        values {
          id
          color
          title
        }
      }
    }
    pointPublicNearRotation(linkName: $linkName) {
      id
      playtime
      onAir
      mediafile {
        id
        title
        performerTitle
        type
      }
    }
    playerDevice {
      debugMode
      bindingCode
      logLevel
      additionalFeatures
      point {
        company {
          country
        }
      }
    }
  }
`;

export const SYNC_STATUS = gql`
  query($linkName: String!) {
    pointStatusPure(linkName: $linkName) {
      weatherStatus {
        temp
        condition
        humidity
        status
      }
      streamUrl
      isAvailable
      isEnabled
      isSleep
      isExpired
      trialEndsAt
      paymentState
      isBillingEnabled
      onlineMediafile {
        id
        title
        performerTitle
        type
        mediafileValue {
          id
          color
          title
        }
      }
    }
  }
`;

export const STATUS = gql`
  subscription($linkName: String) {
    pointStatus(linkName: $linkName) {
      weatherStatus {
        temp
        condition
        humidity
        status
      }
      streamUrl
      isAvailable
      isEnabled
      isSleep
      isExpired
      trialEndsAt
      paymentState
      isBillingEnabled
      onlineMediafile {
        id
        title
        type
        performerTitle
        mediafileValue {
          id
          color
          title
        }
      }
    }
  }
`;

export const STREAM_DISLIKE_MEDIAFILE = gql`
  mutation($mediafileId: Int, $pointId: Int) {
    pointDislikeMediafile(mediafileId: $mediafileId, pointId: $pointId) {
      ok
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation($deviceName: String, $pointLinkName: String, $status: String) {
    pointWebUpdateStatus(
      deviceName: $deviceName
      pointLinkName: $pointLinkName
      status: $status
    ) {
      ok
      errors {
        field
        messages
      }
    }
  }
`;

export const PLAYER_VERSION_CHANGE_MUTATION = gql`
  mutation ($currentVersion: String, $updateChannel: String) {
    deviceUpdatePlayerVersion (currentVersion: $currentVersion, updateChannel: $updateChannel) {
      ok
      requiredVersionUrl
    }
  }
`;

export const DEVICE_COMPANY_REFERRAL_UUID = gql`
  query {
    deviceCompanyReferralUuidQuery
  }
`;
