export default {
  background: "#F5F5F5",
  mobileBackground: "#fff",
  infoBackground: "#EBEBEB",
  mobileInfoBackground: "#FFFFFF",
  headingsColor: "#333333",
  textColor: "#969696",
  iconColor: "#5C5C5C",
  mobileStopStreamBtn: "#15BF95",
  tabsBackground: "#FFFFFF",
  itemHoverColor: "rgba(51, 51, 51, 0.02)",
  tabIconColor: "#BDBDBD",
  activeTabIconColor: "#15BF95",
  dislikeColor: "#E0E0E0",
  activeDislikeColor: "#5C5C5C",
  desktopPlayerBg: "#fff",
};
