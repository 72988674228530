import React from "react";
import styled from "styled-components";

import { Icon } from "../../../ui";

export const PlayerControlButtons = ({
  playing,
  handleClick,
  disabled,
  buffering,
}) => {
  const onClick = () => {
    if (disabled) return;
    handleClick();
  };

  return (
    <PlayerControlButtonsGroup>
      <PlayPauseContainer
        disabled={disabled}
        onClick={onClick}
        active={playing}
      >

          {buffering && playing ? (
            <Icon name="player-loading" size="4" iconColor={"#15bf95"} />
          ) : playing ? (
            <Icon name="stop" size="1.6" />
          ) : (
            <Icon name="play" size="3.2" />
          )}

      </PlayPauseContainer>
    </PlayerControlButtonsGroup>
  );
};

const PlayerControlButtonsGroup = styled.div`
  display: flex;
  justify-content: center;
`;

const PlayPauseContainer = styled.div`
  border: 2px solid #15bf95;
  border-radius: 50%;
  cursor: pointer;
  width: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.8rem;
  ${(props) => props.disabled && "opacity: 0.4; cursor: not-allowed;"}
  &:hover {
    ${(props) =>
      props.theme.iconColor === "#5C5C5C"
        ? "background-color: rgba(0, 0, 0, 0.1)"
        : "background-color: rgba(255, 255, 255, 0.1)"}
  }

  color: ${(props) =>
    props.active ? props.theme.activeTabIconColor : props.theme.iconColor};

  span {
    &:hover {
      opacity: 1;
    }
  }
`;
