import React from "react";
import styled from "styled-components";
import { PasswordInput, ErrorTooltip, Input, withLabelProp } from "../../ui";
import InputMask from "react-input-mask";

export const InputTooltip = ({ type, error, change, value, name, placeholder, onFocus }) => (
  <InputWrap>
    {type === "tel" ? (
      <InputMask
        mask="+7 (999) 999-99-99"
        value={value}
        error={error}
        onChange={(e) => change(e.target.value)}
      >
        {(inputProps) => <Input {...inputProps} />}
      </InputMask>
    ) : type === "password" ? (
      <PasswordInput
        value={value}
        onChange={(e) => change(e.target.value)}
        hasError={error}
        placeholder={placeholder}
        onFocus={onFocus}
      />
    ) : (
      <Input
        name={name}
        value={value}
        type={type}
        error={error}
        placeholder={placeholder}
        onFocus={onFocus}
        onChange={(e) => change(e.target.value)}
      />
    )}

    {error && <ErrorTooltip text={error.messages[0]} />}
  </InputWrap>
);

export const InputTooltipWithLabel = withLabelProp(InputTooltip);

const InputWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  text-align: center;
  font-size: 10px;
  line-height: 18px;
`;
