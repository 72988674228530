import React from "react";
import styled from "styled-components";
import { H4 } from "../../ui";
import { observer } from "mobx-react";
import { useStores } from "../../hooks";
import { SubscriptionStatus } from "../common/subscription-status";


export const Header = observer(({ tab }) => {
  const {
    PointStore: {
      pointStatus: {
        trialEndsAt,
        isBillingEnabled,
        paymentState,
      },
    },
  } = useStores();

  const getTitle = () => {
    switch (tab) {
      case 0:
        return "Плеер";
      case 1:
        return "Дальше в потоке";
      case 2:
        return "История";
      case 3:
        return "Информация";

      default:
        break;
    }
  };
  return (
    <Wrapper>
      <StyledH4>{getTitle()}</StyledH4>

      <SubscriptionStatus
        trialEndsAt={trialEndsAt}
        isBillingEnabled={isBillingEnabled}
        paymentState={paymentState}
      />
    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
  height: 8rem;
  align-items: baseline;
  padding: 2rem 2.4rem 0 2.4rem;
`;

const StyledH4 = styled(H4)`
  color: ${(props) => props.theme.headingsColor};
`;
