import CommonSettingsStore from "./commonSettingsStore";
import PointStore from "./pointStore";
import NotificationStore from "./notificationStore";
import SoundStore from "./soundStore";

export type RootStoreType = {
  CommonSettingsStore: CommonSettingsStore
  NotificationStore: NotificationStore
  PointStore: PointStore
  SoundStore: SoundStore
}

/* An effective pattern is to create a RootStore that instantiates all stores, and share references. */

class RootStore {
  CommonSettingsStore: CommonSettingsStore
  NotificationStore: NotificationStore
  PointStore: PointStore
  SoundStore: SoundStore
  constructor() {
    this.CommonSettingsStore = new CommonSettingsStore(this);
    this.NotificationStore = new NotificationStore(this);
    this.PointStore = new PointStore(this);
    this.SoundStore = new SoundStore(this);
  }
}

export default new RootStore();
