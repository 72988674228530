import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";

export const MobileStabilityTip = observer(() => {
  return (
    <>
      Для стабильного вещания следует:
      <StyledUl>
        <StyledLi>1. Отключить режим энергосбережения и не выключать экран устройства.</StyledLi>
        <StyledLi>2. Не закрывать окно браузера.</StyledLi>
        <StyledLi>3. Поставить телефон на зарядку.</StyledLi>
        <StyledLi>4. Использовать браузеры Safari/Chrome</StyledLi>
      </StyledUl>
      Больше подробностей в памятке.
      &emsp;
      <LinkText href="https://help.zvuk-b2b.com/trable?utm_source=web_player&utm_medium=popup&utm_campaign=mobile_troubleshooting" target="_blank" rel="nofollow,noopener">
        Открыть
      </LinkText>
    </>
  );
});

const LinkText = styled.a`
  color: #15BF95;
`;

const StyledUl = styled.ul`
  padding-left: 0px;
  list-style: none;
`;

const StyledLi = styled.li`
  margin-bottom: 24px;
`;
