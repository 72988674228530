const http = require("http");

export const getNetworkDownloadSpeed = async () => {
  let fileSize = 50000;
  let url = `/other/50?${new Date().valueOf()}${Math.random()}`;
  return await checkDownloadSpeed(url, fileSize);
};

const checkDownloadSpeed = (baseUrl: string, fileSize: number): Promise<{
  bps: number, kbps: number, mbps: number,
}> => {
  let startTime: number;
  return new Promise((resolve, _) => {
    return http.get(baseUrl, (response: any) => {
      response.once("data", () => {
        startTime = new Date().getTime();
      });

      response.once("end", () => {
        const endTime = new Date().getTime();
        const duration = (endTime - startTime) / 1000;
        const bitsLoaded = fileSize * 8;
        const bps = Number((bitsLoaded / duration).toFixed(2));
        const kbps = Number((bps / 1024).toFixed(2));
        const mbps = Number((kbps / 1024).toFixed(2));
        resolve({ bps, kbps, mbps });
      });
    });
  });
};
