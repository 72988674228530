import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import SimpleBar from "simplebar-react";

export const Scrollbar = ({ height, onScroll, children, timeout }) => (
  <ScrollbarContainer height={height} onScroll={onScroll}>
    <SimpleBar style={{ height: "100%" }} timeout={timeout}>
      {children}
    </SimpleBar>
  </ScrollbarContainer>
);

Scrollbar.propTypes = {
  height: PropTypes.string,
  onScroll: PropTypes.func,
  children: PropTypes.any,
  timeout: PropTypes.number
};

Scrollbar.defaultProps = {
  timeout: 1000
};

const ScrollbarContainer = styled.div`
  height: ${({ height }) => height || "100%"};
`;
