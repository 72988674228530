import styled from "styled-components";
import { ContentMedium, H5 } from "../../ui/";

export const ItemBox = styled.div`
  display: flex;
  height: 6.4rem;
  align-items: center;
  padding: 0 2.4rem;
  overflow: hidden;
`;

export const InfoWrapper = styled.div`
  width: calc(100% - 10rem);
`;

export const Time = styled(ContentMedium)`
  color: ${props => props.theme.textColor};
  margin-right: 2rem;
  align-self: flex-start;
  margin-top: 1rem;
`;

export const Title = styled(H5)`
  color: ${props => props.theme.headingsColor};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Artist = styled(ContentMedium)`
  color: ${props => props.theme.textColor};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
