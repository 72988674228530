import React from "react";
import styled from "styled-components";

export const ErrorTooltip = ({ text }) => (
  <Wrap>
    <ErrorText>{text}</ErrorText>
  </Wrap>
);

const Wrap = styled.div`
  position: absolute;
  top: 4.3rem;
`;

const ErrorText = styled.div`
  text-align: left;
  background: #ca5855;
  color: #fff;
  border-radius: 4px;
  padding: 0.6rem 1.2rem;
  min-width: 30rem;
  position: relative;
  font-size: 1rem;
  z-index: 20;
  &:before {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ca5855;
    top: -0.5rem;
    left: 1.6rem;
  }
`;
