import React from "react";
import styled from "styled-components";
import { H3, Icon } from "../../ui/";
import { NetworkBlock } from "../common";
import { useStores } from "../../hooks";
import { observer } from "mobx-react";
import { SubscriptionStatus } from "../common/subscription-status";

export const Header = observer(() => {
  const {
    CommonSettingsStore: {
      internetSpeed,
      isDarkMode,
      toggleTheme,
    },
    PointStore: {
      pointStatus: {
        trialEndsAt,
        isBillingEnabled,
        paymentState,
      },
    },
  } = useStores();

  return (
    <Wrapper>
      <LogoWrapper>
        <Icon size="3" name="logo" />
      </LogoWrapper>

      <StyledH3>Веб-плеер</StyledH3>
      <SettingsBox>
        <SubscriptionStatus
          trialEndsAt={trialEndsAt}
          isBillingEnabled={isBillingEnabled}
          paymentState={paymentState}
        />

        <NetworkBlock speed={internetSpeed} />

        <ToogleIconBox isDarkMode={isDarkMode}>
          <span onClick={toggleTheme} style={{ cursor: "pointer" }}>
            <Icon
              iconColor={isDarkMode ? "#F5F5F5" : "#5c5c5c"}
              name={isDarkMode ? "dark-mode" : "light-mode"}
            />
          </span>
        </ToogleIconBox>
      </SettingsBox>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 6.4rem;
  padding: 0 3.2rem 0 2rem;
`;

const StyledH3 = styled(H3)`
  margin-left: 2.4rem;
  color: ${(props) => props.theme.headingsColor};
`;

const SettingsBox = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  user-select: none;
`;

const ToogleIconBox = styled.div`
  padding-left: 3.2rem;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: -0.4rem;
    display: block;
    width: 0.1rem;
    height: 3.2rem;
    background-color: ${(props) => (props.isDarkMode ? "#969696" : "#E0E0E0")};
  }
`;

const LogoWrapper = styled.div`
  color: ${(props) => props.theme.iconColor};
  span {
    &:hover {
      opacity: 1;
    }
  }
`;
