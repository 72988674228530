import React, { useState } from "react";
import styled from "styled-components";
import { H3, Icon } from "../../ui/";
import { Playlist, History } from "../common";

export const DesktopTabs = ({
  history,
  playlist,
  isLoggedIn,
  toggleAuthWindow,
  pointId
}) => {
  const [showHistory, setShowHistory] = useState(false);
  return (
    <Wrapper>
      <IconBox
        className={showHistory === false && "active"}
        onClick={() => setShowHistory(false)}
      >
        <Icon name="playlistPlay"/>
      </IconBox>
      <IconBox
        className={showHistory === true && "active"}
        onClick={() => setShowHistory(true)}
      >
        <Icon name="history"/>
      </IconBox>

      {showHistory ? (
        <>
          <StyledH3>История</StyledH3>
          <History
            toggleAuthWindow={toggleAuthWindow}
            isLoggedIn={isLoggedIn}
            history={history}
            pointId={pointId}
          />
        </>
      ) : (
        <>
          <StyledH3>Дальше в потоке</StyledH3>
          <Playlist playlist={playlist} />
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-radius: 16px;
  width: 48rem;
  padding: 3.2rem 0;
  background-color: ${props => props.theme.tabsBackground};
`;

const IconBox = styled.span`
  color: ${props => props.theme.tabIconColor};
  &.active {
    color: ${props => props.theme.activeTabIconColor};
  }
  cursor: pointer;
  margin-right: 1.6rem;
  &:first-child {
    margin-left: 2.4rem;
  }
`;

const StyledH3 = styled(H3)`
  color: ${props => props.theme.headingsColor};
  margin: 3.2rem 0 2.8rem 2.4rem;
`;
