import React from "react";
import { ItemBox, Time, Title, Artist, InfoWrapper } from "./playlist-parts";
import Skeleton from "react-loading-skeleton";

export const Playlist = ({ playlist, isMobile }) => {
  const skeletons = [];
  for (let i = 0; i < 10; i++) {
    skeletons.push(
      <ItemBox key={i}>
        <Time>
          <Skeleton height={12} width={35} />
        </Time>
        <div>
          <Title>
            <Skeleton height={12} width={128} />
          </Title>
          <Artist>
            <Skeleton height={12} width={isMobile ? "150%" : 354} />
          </Artist>
        </div>
      </ItemBox>
    );
  }
  return (
    <>
      {playlist
        ? playlist.map(item => (
            <ItemBox key={item.id}>
              <Time>{new Date(item.playtime).toTimeString().slice(0, 5)}</Time>
              <InfoWrapper>
                <Title title={item.mediafile.title}>
                  {item.mediafile.title}
                </Title>
                <Artist title={item.mediafile.performerTitle}>
                  {item.mediafile.performerTitle}
                </Artist>
              </InfoWrapper>
            </ItemBox>
          ))
        : [skeletons]}
    </>
  );
};
