import React from "react";
import { ContentLarge } from "../../ui/";
import styled from "styled-components";

export const NetworkBlock = ({ speed }) => {
  const renderStatus = () => {
    if (speed === null)
      return <ContentLarge style={{ color: "#CA5855" }}>Оффлайн</ContentLarge>;
    if (speed > 512) {
      return <ContentLarge style={{ color: "#15BF95" }}> Высокое</ContentLarge>;
    } else if (speed > 256 && speed < 512) {
      return <ContentLarge style={{ color: "#FFA726" }}>Среднее</ContentLarge>;
    } else {
      return <ContentLarge style={{ color: "#CA5855" }}>Низкое</ContentLarge>;
    }
  };

  return (
    <NetworkBox>
      <NetworkLabel>Качество сети: </NetworkLabel> {renderStatus()}
    </NetworkBox>
  );
};

const NetworkBox = styled.div`
  display: flex;
  margin-right: 3.2rem;
`;

const NetworkLabel = styled(ContentLarge)`
  color: ${(props) => props.theme.textColor};
  margin-right: 1.6rem;
`;
