import React from "react";
import { Location } from "./location";
import { Stream } from "./stream";
import { Weather } from "./weather";
import { Feedback } from "./feedback";
import { DesktopSection } from "../../banner/DesktopSection";
import { BannerInfoSection } from "../../banner/BannerInfoSection";
import styled from "styled-components";

export const Information = ({ pointPublic, weatherStatus = {}, isMobile }) => {
  const { comment, address, streamTitle } = pointPublic;
  const { status } = weatherStatus;

  return (
    <Wrapper>
      {isMobile && <BannerInfoSection />}
      <Stream
        isMobile={isMobile}
        streamTitle={streamTitle}
      />

      <Location address
                  ={address} isMobile={isMobile} title={comment} />
      {!isMobile && <Feedback inline={false} />}
      {!isMobile && <DesktopSection />}
      {status === "ok" && <Weather {...weatherStatus} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 48rem;
  margin-right: 6.8rem;

  @media (max-width: 1128px) {
    margin-right: 0;
    width: 100%;
  }
`;
