import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { ContentSmallBold } from "../typography";

export const DefaultInput = styled.input.attrs((props) => ({
  maxLength: 40,
}))`
  width: 100%;
  padding: 0.6rem 1.2rem;
  background-color: #ebebeb;
  border-radius: 0.4rem;
  color: #5c5c5c;
  outline: none;
  border-width: 0.1rem;
  border-style: solid;
  border-color: #ebebeb;

  font-family: "PT Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.8rem;

  ${(props) => props.hasError && hasErrorCss}
  ${(props) => props.disabled && disabledCss}

  &::placeholder {
    color: #969696;
  }

  &:focus {
    background-color: #fff;
    border-color: #15bf95;
  }
  &::selection {
    background: rgba(21, 191, 109, 0.3);
  }

  ${(props) => {
    if (props.secondary) {
      return css`
        border-color: transparent;
        background-color: transparent;

        &:hover {
          border-color: #bdbdbd;
        }

        &:focus {
          border-color: #15bf95;
        }
      `;
    }
  }}
`;

const hasErrorCss = css`
  border-color: #ca5855;
  background-color: #fff;
`;

const disabledCss = css`
  border-color: transparent;
  background-color: transparent;
`;

DefaultInput.propTypes = {
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export const InputWithFocus = styled(DefaultInput).attrs((props) => ({
  maxLength: 40,
  onFocus: props.onFocus,
}))``;

InputWithFocus.defaultProps = {
  onFocus: (e) => e.target.select(),
};

export function withLabel(Component, label) {
  return function WrapperComponent(props) {
    return (
      <Wrapper>
        {<Label>{label}</Label>}
        <Component {...props} />
      </Wrapper>
    );
  };
}

export function withLabelProp(Component) {
  return function WrapperComponent(props) {
    const { label, ...otherProps } = props;
    return (
      <Wrapper>
        <CommonLabel>{label}</CommonLabel>
        <Component {...otherProps} />
      </Wrapper>
    );
  };
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Label = styled(ContentSmallBold)`
  position: absolute;
  top: -2.4rem;
  color: #5c5c5c;
`;

export const CommonLabel = styled(Label)`
  position: absolute;
  color: #BDBDBD;

  top: ${({ top }) => top || "-2.2rem"};
  left: ${({ left }) => left || "0"};
`;
