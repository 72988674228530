import React from "react";
import { Icon, ContentMedium } from "../../../ui";
import Skeleton from "react-loading-skeleton";
import { Content, IconWrapper, Section, StyledH5 } from "./common";
import styled from "styled-components";

export const Location = ({ address, isMobile, title }) => (
  <Section>
    <Content>
      <IconWrapper>
        <Icon name="place" />
      </IconWrapper>

      <div>
        <StyledH5 style={{ marginBottom: "0.3rem" }}>
          {address || <Skeleton height={12} width={isMobile ? "150%" : 354} />}
        </StyledH5>
        <StyledContentMedium>
          {title === undefined ? (
            <Skeleton height={12} width={128} />
          ) : title === "" ? null : (
            title
          )}
        </StyledContentMedium>
      </div>
    </Content>
  </Section>
);

const StyledContentMedium = styled(ContentMedium)`
  margin-left: 1.6rem;
  width: 100%;
  color: ${(props) => props.theme.textColor};
`;
