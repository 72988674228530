import React from "react";
import styled, { css } from "styled-components";
import { Icon, ContentLarge, ContentMediumBold } from "../ui";
import { useStores } from "../hooks";
import { observer } from "mobx-react";
import { NotificationTipWrapper } from "./mobile/notification-tip-wrapper";

export const Notification = observer(() => {
  const {
    NotificationStore: {
      type,
      text,
      show,
      clickableAreaProps,
      customButtons,
      closeNotification,
      closeCallback
    }
  } = useStores();

  const clickableAreaHandler = () => {
    if (!type === "click") return;
    closeNotification();
  };

  const renderCustomButtons = () => (
    <CustomButtonsWrapper>
      {customButtons.map((btn, index) => (
        <ButtonWrapper key={index}>{btn}</ButtonWrapper>
      ))}
    </CustomButtonsWrapper>
  );

  const handleClose = () => {
    closeNotification();
    closeCallback();
  };

  return (
    <AlertContainer>
      {type === 'tip'
        ? (
          <NotificationTipWrapper close={handleClose}/>
        )
        : (
          <Wrapper show={show} type={type} withCustomButtons={!!customButtons}>
            {type === "click" ? (
              <ClickableArea
                type={type}
                onClick={clickableAreaHandler}
                {...clickableAreaProps}
              >
                {text}
              </ClickableArea>
            ) : (
              <TextWrapper withCustomButtons={!!customButtons}>{text}</TextWrapper>
            )}
            {customButtons ? (
              renderCustomButtons()
            ) : (
              <CloseIconBox onClick={handleClose} type={type}>
                <Icon name="close" iconColor="#fff" iconColorHover="#fff" />
              </CloseIconBox>
            )}
          </Wrapper>
        )
      }
    </AlertContainer>
  );
});

const AlertContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 999;
  @media (max-width: 1128px) {
    left: initial;
    right: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: ${props => (props.withCustomButtons ? "flex-start" : "center")};
  justify-content: space-between;
  position: absolute;
  transform: translateX(-50%);
  bottom: ${props => (props.show ? "10.2rem" : "-10rem")};
  width: 53.6rem;

  @media (max-width: 1128px) {
    transform: translateX(-102%);
  }

  @media (max-width: 576px) {
    width: 96vw;
    bottom: ${props => (props.show ? "9.5rem" : "-25rem")};
  }

  ${({ type }) =>
    type === "click"
      ? css`
          height: 4.8rem;
        `
      : css`
          min-height: ${props =>
            props.withCustomButtons ? "9.6rem" : "4.8rem"};
        `}

  box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.2), 0px 9px 46px rgba(0, 0, 0, 0.12),
    0px 24px 38px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  background-color: ${props =>
    props.type === "error" ? "#CA5855" : "#333333"};

  transition: bottom ease 0.3s;
  overflow: hidden;
`;

const TextWrapper = styled(ContentLarge)`
  color: #fff;
  padding: 1.2rem 2.4rem;
  width: ${props =>
    props.withCustomButtons ? "calc(100% - 12rem);" : "calc(100% - 5.8rem);"};
`;

const ClickableArea = styled.div`
  display: flex;
  align-items: center;

  width: 45.4rem;
  height: 100%;

  color: #fff;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${({ type }) =>
      type === "error" ? "#F26765" : "#5C5C5C"};
  }
`;

const CloseIconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  height: 100%;
  width: 5.8rem;
  border-left: 1px solid #f5f5f5;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${({ type }) =>
      type === "error" ? "#F26765" : "#5C5C5C"};
  }
`;

const CustomButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 12rem;
  align-items: center;
  border-left: 1px solid #f5f5f5;
  height: 100%;
  position: absolute;
  right: 0;
`;

const ButtonWrapper = styled(ContentMediumBold)`
  min-height: 4.8rem;
  height: 100%;
  color: #fff;
  width: 100%;
  cursor: pointer;
  position: relative;
  span {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
  }

  &:not(:only-child) {
    &:first-child {
      border-bottom: 1px solid #f5f5f5;
    }
  }
  &:hover {
    background-color: ${({ type }) =>
      type === "error" ? "#F26765" : "#5C5C5C"};
  }
`;
