import React from "react";
import styled from "styled-components";
import { Range, getTrackBackground } from "react-range";

import { Icon } from "../../../ui";

export const PlayerVolume = ({ volume, handleVolume, hasSound }) => {
  const handleMute = () => {
    if (!hasSound) return;

    handleVolume(volume[0] === 0 ? [50] : [0]);
  };

  const renderIcon = () => {
    if (volume[0] > 50) {
      return <Icon iconColor="#15BF95" name="volume-hight" size="2.4" />;
    } else if (volume[0] > 0) {
      return <Icon iconColor="#15BF95" name="volume-low" size="2.4" />;
    } else return <Icon iconColor="#15BF95" name="volume-mute" size="2.4" />;
  };

  return (
    <PlayerVolumeBox>
      <PlayerVolumeIconContainer onClick={handleMute}>
        {renderIcon()}
      </PlayerVolumeIconContainer>
      <RangeContainer>
        <Range
          step={0.1}
          min={0}
          max={100}
          values={volume}
          onChange={volume => {
            if (hasSound) handleVolume(volume);
          }}
          renderTrack={({ props, isDragged, children }) => (
            <TrackWrapper
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              min={0}
              max={100}
              values={volume}
              isDragged={isDragged}
            >
              <Track {...props}>{children}</Track>
            </TrackWrapper>
          )}
          renderThumb={({ props }) => <Thumb {...props} />}
        />
      </RangeContainer>
    </PlayerVolumeBox>
  );
};

export const PlayerVolumeBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const PlayerVolumeIconContainer = styled.div`
  cursor: pointer;
`;

const RangeContainer = styled.div`
  width: 11rem;
`;

const Thumb = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #15bf95;
  border: 2px solid ${props => props.theme.desktopPlayerBg};
  opacity: 1;
`;

const Track = styled.div`
  width: 100%;
  height: 2px;
  position: relative;
  margin-left: 12px;
`;

const TrackWrapper = styled.div`
  padding: 16px 0;
  cursor: pointer;
  ${Track} {
    background: ${props =>
      getTrackBackground({
        values: props.values,
        min: props.min,
        max: props.max,
        colors: ["#15BF95", "#969696"]
      })};
  }

  &:hover ${Thumb} {
    opacity: 1;
  }
`;
