import React, {useMemo} from "react";
import styled from "styled-components";
import { ContentLarge } from "../../ui/";
import { PaymentStateEnum } from "../../stores/pointStore";
import moment from "moment-timezone";

interface ISubscriptionStatusProps {
  trialEndsAt: string | null,
  paymentState: PaymentStateEnum,
  isBillingEnabled: boolean,
}

export const SubscriptionStatus: React.FC<ISubscriptionStatusProps> = ({
  trialEndsAt,
  paymentState,
  isBillingEnabled
}) => {
  const daysCount: number = useMemo(() => {
    const timeNow = moment();
    const momentTrialEndsAt = moment(trialEndsAt);

    if (trialEndsAt) {
      if (timeNow.isAfter(momentTrialEndsAt)) return 0;

      const diffDays = momentTrialEndsAt.diff(timeNow, "days");

      // Rounding up
      return diffDays + 1;
    }

    return 0;
  }, [trialEndsAt]);

  const daysUnitText: string = useMemo(() => {
    const rest = daysCount % 10;

    if (daysCount > 10 && daysCount < 20) {
      return 'дней';
    }
    if (rest > 1 && rest < 5) {
      return 'дня';
    }
    if (rest === 1) {
      return 'день';
    }

    return 'дней';
  }, [daysCount]);

  const renderStatus = () => {
    if (daysCount === 1 && moment().endOf("day").isAfter(moment(trialEndsAt))) {
      return (
        <>
          <StatusLabel>Тестовый период: </StatusLabel>
          <ContentLarge style={{ color: "#CA5855" }}>Сегодня</ContentLarge>
        </>
      );
    } else if (daysCount === 0) {
      return (
        <>
          <StatusLabel>Тестовый период: </StatusLabel>
          <ContentLarge style={{ color: "#CA5855" }}>Закончен</ContentLarge>
        </>
      );
    } else {
      return <ContentLarge style={{ color: "#15BF95" }}>Тестовый период: {daysCount} {daysUnitText}</ContentLarge>;
    }
  };

  return (isBillingEnabled && trialEndsAt && (paymentState === PaymentStateEnum.TRIAL || paymentState === PaymentStateEnum.UNPAID))
    ? (
      <StatusBox>
        {renderStatus()}
      </StatusBox>
    )
    : null;
};

const StatusBox = styled.div`
  display: flex;
  margin-right: 3.2rem;
`;

const StatusLabel = styled(ContentLarge)`
  color: ${(props) => props.theme.textColor};
  margin-right: 1.6rem;
`;
