// @ts-nocheck
import React from 'react';
import {client} from "../apolloClient";
import useInterval from "../hooks/useInterval";
import {PLAYER_VERSION_CHANGE_MUTATION} from "../gql";
import {createLogger} from "../utils/logging";
import {versionInfo} from "../versionInfo";
import { getWeb3Link } from '../utils/conversions';

const IS_V3_REG_EXP = /v3_/i;
const BINDING_CODE_REG_EXP = /^.*\/point\/(?<link>[A-Za-z0-9]+).*$/i;

const logger = createLogger('@updater');

const isThirdVersion = (location: string) => {
  try {
    return IS_V3_REG_EXP.test(location);
  } catch (err) {
    return false;
  }
};

const getBindingCode = (location: string) => {
  try {
    return location.match(BINDING_CODE_REG_EXP)?.groups?.link;
  } catch (err) {
    return "";
  }
};

const updaterDisabled = (window as any)._env_.DISABLE_UPDATER === 'yes';
console.log(updaterDisabled);

const parseCurrentVersion = (): string => {
  return versionInfo.version;
}

const parseUpdateChannel = (): string => {
  return versionInfo.channel;
}

interface IPlayerVersionChangeResult {
  data?: {
    deviceUpdatePlayerVersion: {
      ok: boolean
      requiredVersionUrl: string
    }
  }
}

export const Updater: React.FC = ({children}) => {
  const [version, setVersion] = React.useState<string>(window.location.href);
  const {
    currentVersion, updateChannel,
  } = React.useMemo<{ currentVersion: string, updateChannel: string }>(() => ({
    currentVersion: parseCurrentVersion(),
    updateChannel: parseUpdateChannel(),
  }), []);

  const getVersion = () => {
    client.mutate({
      mutation: PLAYER_VERSION_CHANGE_MUTATION,
      variables: {
        currentVersion: currentVersion,
        updateChannel: updateChannel,
      }
    }).then(
      (data: IPlayerVersionChangeResult) => {
        try {
          logger.debug('Fetched version', {
            data: data.data,
            version: data?.data?.deviceUpdatePlayerVersion.requiredVersionUrl,
          });
          if (data?.data?.deviceUpdatePlayerVersion) {
            setVersion(data.data.deviceUpdatePlayerVersion.requiredVersionUrl);
          }
        } catch (e) {
          logger.debug('Failed to update version', {err: e.toString()});
        }
      }
    )
  }

  React.useEffect(getVersion, []);

  useInterval(() => {
    getVersion();
  }, 60 * 1000);

  React.useEffect(() => {
    const currLocation = window.location.href;

    const bindingCode = getBindingCode(currLocation);
    const isThird = isThirdVersion(currLocation);

    if (isThird && bindingCode) {
      const web3Link = getWeb3Link(bindingCode);

      window.location = web3Link;
    }
    if (version && currLocation !== version) {
      logger.info("Required version changed, redirecting", {
        version,
        location: currLocation,
      });
      if (!updaterDisabled) {
        window.location = version as unknown as Location;
      } else {
        logger.info("Updater disabled, skipping redirect", {
          version,
          location: currLocation,
        });
      }
    }
  }, [version]);

  return (
    <>
      {children}
    </>
  )
}
