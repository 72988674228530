import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import { ContentMediumBold, Spinner } from "../";

export const Button = ({
  type,
  isLoading,
  onClick,
  disabled,
  className,
  transparent,
  style,
  inverted,
  icon,
  children
}) => {
  const isIconButton = icon !== undefined && icon !== null;
  const isIconTextButton =
    isIconButton && children !== undefined && children !== null;

  let _class = "";
  if (isIconButton) _class = "icon";
  if (isIconTextButton) _class = "icon-text";

  const calculatedClassName = className ? _class + className : _class;

  return (
    <Btn
      onClick={onClick}
      disabled={isLoading || disabled}
      isLoading={isLoading}
      type={type}
      className={calculatedClassName}
      transparent={transparent}
      style={style}
      inverted={inverted}
    >
      {icon}
      <ContentMediumBold>{children}</ContentMediumBold>
      {isLoading && (
        <SpinnerBox>
          <Spinner
            scale={0.8}
            color={inverted ? "var(--white)" : getSpinnerColorFromType(type)}
          />
        </SpinnerBox>
      )}
    </Btn>
  );
};

const Btn = styled.button`
  display: flex;
  align-items: center;

  position: relative;

  border-radius: 4px;
  outline: none;
  padding: 0.4rem 1.6rem 0.6rem;
  height: 3.2rem;
  min-width: 7.2rem;

  color: #fff;

  border: none;

  cursor: pointer;
  &:disabled {
    cursor: default;
  }

  ${props => props.type === "primary" && PrimaryCss};
  ${props =>
    props.type === "secondary" &&
    (props.inverted ? SecondaryInvertedCss : SecondaryCss)};
  ${props => props.type === "danger" && DangerCss};

  ${({ transparent }) =>
    transparent &&
    css`
      background-color: transparent;
    `};

  &.icon {
    min-width: 0;
    height: auto;
    padding: 0.4rem;
    display: flex;
  }

  &.icon-text {
    min-width: 0;
    padding: 0.4rem 0.8rem;

    & > :nth-child(2) {
      margin-left: 0.6rem;
    }
  }

  /* some workaround for buttons with <a /> in it */
  a {
    display: flex;
  }
`;

Btn.propTypes = {
  type: PropTypes.oneOf(["primary", "secondary", "danger"]),
  isLoading: PropTypes.bool
};

Btn.defaultProps = {
  type: "primary",
  isLoading: false
};

const PrimaryCss = css`
  background-color: #15BF95;

  &:enabled:hover {
    background-color: #1ea966;
  }

  &:active,
  &:enabled:active {
    background-color: #1ea966;
    color: rgba(255, 255, 255, 0.6);
  }

  &:disabled {
    background-color: rgba(21, 191, 109, 0.4);
    color: rgba(255, 255, 255, 0.9);
  }
`;

const SecondaryCss = css`
  background-color: #ebebeb;
  color: var(--black-lt-1);

  &:enabled:hover {
    /* background-color: var(--black-lt-4); */
    background-color: rgba(0, 0, 0, 0.07);
  }

  &:active,
  &:enabled:active {
    /* background-color: var(--black-lt-4); */
    background-color: rgba(0, 0, 0, 0.1);
    color: rgba(92, 92, 92, 0.6);
  }

  &:disabled {
    background-color: rgba(235, 235, 235, 0.4);
    color: rgba(92, 92, 92, 0.4);
  }
`;

const SecondaryInvertedCss = css`
  background-color: rgba(255, 255, 255, 0.07);
  color: var(--white);

  &:enabled:hover {
    /* background-color: var(--black-lt-4); */
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:active,
  &:enabled:active {
    /* background-color: var(--black-lt-4); */
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.6);
  }

  &:disabled {
    background-color: rgba(255, 255, 255, 0.07);
    color: rgba(255, 255, 255, 0.4);
  }
`;

const DangerCss = css`
  background-color: #ca5855;
  color: var(--white);

  &:enabled:hover {
    background-color: #b84e4c;
  }

  &:active,
  &:enabled:active {
    background-color: #b84e4c;
    color: rgba(255, 255, 255, 0.6);
  }

  &:disabled {
    background-color: rgba(202, 88, 85, 0.4);
    color: rgba(255, 255, 255, 0.9);
  }
`;

const SpinnerBox = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
`;

const getSpinnerColorFromType = type => {
  switch (type) {
    case "primary":
      return "#15BF95";
    case "secondary":
      return "#5C5C5C";
    case "danger":
      return "#CA5855";
    default:
      return undefined;
  }
};
