import React from "react";
import {Icon} from "../../../ui";
import Skeleton from "react-loading-skeleton";
import {Content, IconWrapper, Section, StyledH5} from "./common";

export const Stream = ({
  streamTitle,
  isMobile,
}) => (
    <Section>
      <Content>
        <IconWrapper>
          <Icon name="equalizer"/>
        </IconWrapper>

        <StyledH5>
          {streamTitle || (
            <Skeleton height={12} width={isMobile ? "100%" : 256}/>
          )}
        </StyledH5>
      </Content>
    </Section>
);
