import React from "react";
import { Icon, ContentSmall } from "../../../ui";
import { Content, IconWrapper, Section, StyledH5 } from "./common";
import styled from "styled-components";

export const Weather = ({ condition, temp, humidity }) => (
  <Section>
    <Content>
      <IconWrapper>
        <Icon name="cloud" />
      </IconWrapper>

      <div>
        <StyledH5>{`${condition}, ${temp} °С, влажность ${humidity}`}</StyledH5>
        <StyledContentSmall style={{ marginTop: "0.5rem" }}>
          Настроение музыки зависит от погодных условий в вашем регионе.
        </StyledContentSmall>
      </div>
    </Content>
  </Section>
);

const StyledContentSmall = styled(ContentSmall)`
  margin-left: 1.6rem;
  color: ${(props) => props.theme.textColor};
`;
