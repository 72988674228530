import React, { useState } from "react";
import styled from "styled-components";
import { useMutation } from "@apollo/react-hooks";

import { Button } from "../../ui";
import { TOKEN_AUTH } from "./gql";
import { useStores } from "../../hooks";
import { InputTooltipWithLabel } from "./input";
import { observer } from "mobx-react";

export const LoginEmail = observer(() => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const {
    NotificationStore: { showNotification },
    CommonSettingsStore: { handleLogin, toggleLoginPage },
  } = useStores();

  const [tokenAuth, { loading }] = useMutation(TOKEN_AUTH, {
    variables: { username, password },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    tokenAuth()
      .then(({ data }) => {
        localStorage.setItem("authToken", data.tokenAuth.token);
        toggleLoginPage();
        handleLogin();
      })
      .catch(handleError);
  };

  const handleError = (e) => {
    if (e.message.includes("credentials")) {
      showNotification({
        type: "error",
        text: "Неправильный логин или пароль",
      });
      return;
    }

    if (e.message.includes("verified")) {
      showNotification({
        type: "error",
        text: "Подтвердите адрес электронной почты",
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Group>
        <InputTooltipWithLabel
          label="E-mail"
          type="email"
          name="email"
          change={setUsername}
          value={username}
        />
      </Group>
      <Group>
        <InputTooltipWithLabel
          label="Пароль"
          type="password"
          change={setPassword}
          value={password}
        />
      </Group>

      <Btn
        type="primary"
        disabled={loading || !username.trim() || !password.trim()}
      >
        Войти
      </Btn>
    </Form>
  );
});

const Btn = styled(Button)`
  margin-top: 1.0rem;
  margin-bottom: 1.0rem;
`;

const Form = styled.form`
  margin-top: 4rem;
  margin-bottom: 2.2rem;
  width: 100%;
`;

const Group = styled.div`
  display: flex;
  align-items: center;
  padding: 2.6rem 0;
`;
