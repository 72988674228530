export default {
  background: "#5C5C5C",
  mobileBackground: "#464646",
  infoBackground: "#666666",
  mobileInfoBackground: "#464646",
  headingsColor: "#F5F5F5",
  textColor: "#BDBDBD",
  iconColor: "#FFFFFF",
  mobileStopStreamBtn: "#FFFFFF",
  tabsBackground: "#464646",
  itemHoverColor: "rgba(255, 255, 255, 0.02)",
  tabIconColor: "#969696",
  activeTabIconColor: "#F5F5F5",
  dislikeColor: "#969696",
  activeDislikeColor: "#F5F5F5",
  desktopPlayerBg: "#333333"
};
