import {logDnaSend} from "./logdnaLogger";

let logLevel = (localStorage.getItem('logLevel') || 'warn') as keyof typeof logLevelMap;

if (logLevel && !localStorage.getItem('logLevel')) {
  localStorage.setItem('logLevel', logLevel);
}

export const changeLogLevel = (level: string) => {
  logLevel = level as keyof typeof logLevelMap;
  localStorage.setItem('logLevel', level);
}

const logLevelMap = {
  debug: 0,
  info: 1,
  warn: 2,
  error: 3,
}

type logParams = {
  scope?: string,
  [key: string]: any,
};

const shouldSendLogs = (globalLevel: keyof typeof logLevelMap, level: keyof typeof logLevelMap) => {
  try {
    return logLevelMap[globalLevel] <= logLevelMap[level];
  } catch {
    return true;
  }
}

export function createLogger(scope: string) {
  return {
    debug: (message: string, params = {}) => debug(message, {scope, ...params}),
    info: (message: string, params = {}) => info(message, {scope, ...params}),
    warn: (message: string, params = {}) => warn(message, {scope, ...params}),
    error: (message: string, params = {}) => error(message, {scope, ...params}),
  }
}

export function debug(message: string, {scope, ...params}: logParams = {}) {
  const formattedMessage = formatMessage(message, scope, params);
  if (!shouldSendLogs(logLevel, 'debug')) {
    return;
  }

  console.debug(formattedMessage);
  logDnaSend('debug', formattedMessage);
}

export function info(message: string, {scope, ...params}: logParams = {}) {
  const formattedMessage = formatMessage(message, scope, params);
  if (!shouldSendLogs(logLevel, 'info')) {
    return;
  }
  console.info(formattedMessage);
  logDnaSend('info', formattedMessage);
}

export function warn(message: string, {scope, ...params}: logParams = {}) {
  const formattedMessage = formatMessage(message, scope, params);
  if (!shouldSendLogs(logLevel, 'warn')) {
    return;
  }
  console.warn(formattedMessage);
  logDnaSend('warn', formattedMessage);
}

export function error(message: string, {scope, ...params}: logParams = {}) {
  const formattedMessage = formatMessage(message, scope, params);
  if (!shouldSendLogs(logLevel, 'error')) {
    return;
  }
  console.error(formattedMessage);
  logDnaSend('error', formattedMessage);
}

function formatMessage(message: string, scope: undefined | string, params: { [key: string]: any }) {
  const formattedParams = Object.entries(params || {}).map(([key, value]) => {
    return `${key}=${JSON.stringify(value)}`;
  }).join('; ')

  return ((scope ? `[${scope}] ${message}` : message) + (formattedParams ? (" | " + formattedParams) : "")).trim();
}
