import {getLinkName} from "../stores/commonSettingsStore";

const LOGDNA_HOSTNAME = 'https://logdna.muzlab.ru/logs/ingest';
const LOGDNA_INGESTION_KEY = '626e6323b98a4eefd7689b4184ab1ba2';

export function logDnaSend(level: string, message: string) {
  const linkName: string = getLinkName();
  if (linkName.length > 0) {
    fetch(
      `${LOGDNA_HOSTNAME}?hostname=Muzlab&now=${Date.now()}&apikey=${LOGDNA_INGESTION_KEY}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          lines: [
            {
              timestamp: Date.now(),
              line: message,
              app: `Muzlab-Web-${linkName}`,
              level: level.toUpperCase(),
            }
          ]
        }),
      })
      .then(async (resp) => {
        if (resp.status !== 200) {
          console.debug(resp?.status, resp?.statusText, resp?.json(), resp?.type);
        }
      })
      .catch((e) => {
        console.error(e);
      })
  }
}
