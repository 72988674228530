import React from "react";
import styled from "styled-components";

import { MainContent } from "./MainContent";
import { ScreenBanner } from "./styles";

export const BannerInfoSection = () => {
  return (
    <BannerInfoSectionStyled>
      <InfoSectionScreenBanner>
        <MainContent />
      </InfoSectionScreenBanner>
    </BannerInfoSectionStyled>
  );
};

const BannerInfoSectionStyled = styled.div`
  padding: 16px;
`;

const InfoSectionScreenBanner = styled(ScreenBanner)`
  border-radius: 8px;
`;
