import React from "react";
import styled from "styled-components";

import BannerDesktop from "../../assets/svg/banner-desktop.svg";
import { Section } from "../common/information/common";
import { MainContent } from "./MainContent";

export const DesktopSection = () => {
  return (
    <DesktopSectionContainer>
      <DesktopSectionStyled>
        <MainContent />
      </DesktopSectionStyled>
    </DesktopSectionContainer>
  );
};

const DesktopSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DesktopSectionStyled = styled(Section)`
  background: url(${BannerDesktop}) 115% 14px no-repeat,
    linear-gradient(180deg, #7852e4 0%, #6e57fb 100%);
  height: 296px;
  background-size: 202px 274px, cover;
`;
