// @ts-nocheck
import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { useStores } from "../../hooks";
import { ContentLarge } from "../../ui/typography";

export const NotificationTipWrapper = observer(({close}) => {
  const {
    NotificationStore: {
      text,
      show,
    }
  } = useStores();

  return (
    <Wrapper show={show}>
      <TextWrapper>{text}</TextWrapper>
      <ButtonWrapper onClick={close}>Понятно, спасибо</ButtonWrapper>
    </Wrapper>
  );
});

const Wrapper = styled.div<{show: boolean}>`
  display: flex;
  flex-direction: column;
  width: 96vw;
  position: absolute;
  bottom: 9.5rem;
  box-shadow:
    0px 11px 15px rgba(0, 0, 0, 0.2),
    0px 9px 46px rgba(0, 0, 0, 0.12),
    0px 24px 38px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  background-color: #333333;
  overflow: hidden;
  transition: transform ease 0.3s;
  transform: ${props => props.show
    ? "translateX(-102%) translateY(0)"
    : "translateX(-102%) translateY(calc(100% + 9.5rem))"
  };
`;

const TextWrapper = styled(ContentLarge)`
  padding: 24px 24px 40px 24px;
  color: #fff;
`;

const ButtonWrapper = styled(ContentLarge)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 64px;
  color: #fff;
  border-top: 1px solid #fff;
`;
