import { decorate, observable, action } from "mobx";
import { getNetworkDownloadSpeed } from "../utils/network-watcher";
import {createLogger} from "../utils/logging";
import {RootStoreType} from "./rootStore";

const logger = createLogger('@store/common');

let _linkName: string | null = null;

export const getLinkName = (): string => {
  if (!_linkName) {
    const match = window.location.pathname.match(/^.*\/point\/(?<link>[A-Za-z0-9]+).*$/);
    _linkName = (match && match.groups) ? match.groups.link : '';
  }
  return _linkName;
}

class CommonSettingsStore {
  rootStore: RootStoreType
  linkName: string

  constructor(rootStore: RootStoreType) {
    this.rootStore = rootStore;
    this.linkName = getLinkName();
    logger.info('Got link name', {linkName: this.linkName});
  }

  windowWidth: number = window.innerWidth;
  windowHeight: number = window.innerHeight;
  internetSpeed: number | null = null;
  isLoggedIn = !!localStorage.getItem("authToken");
  isMobile: boolean = this.windowWidth <= 1128;
  isDarkMode: boolean = Boolean(localStorage.getItem("isDarkMode"));
  showLoginPage: boolean = false;

  toggleTheme = () => {
    this.isDarkMode = !this.isDarkMode;
    localStorage.setItem("isDarkMode", String(!this.isDarkMode));
  };

  setInternetWatcher = () => {
    getNetworkDownloadSpeed().then((data) => this.setInternetSpeed(data.kbps)); // get speed for 1st render
    setInterval(() => {
      getNetworkDownloadSpeed().then((data) =>
        this.setInternetSpeed(data.kbps)
      );
    }, 10000);
  };

  setWidth = (width: number) => {
    this.windowWidth = width;
    this.isMobile = width <= 1128;
  };

  handleOffline = () => {
    this.setInternetSpeed(null);
  };

  setHeight = (height: number) => {
    this.windowHeight = height;
  };

  setInternetSpeed = (speed: number | null) => {
    this.internetSpeed = speed;
  };

  handleLogout = () => {
    this.isLoggedIn = false;
    localStorage.removeItem("authToken");
  };

  handleLogin = () => {
    this.isLoggedIn = true;
  };

  toggleLoginPage = () => {
    this.showLoginPage = !this.showLoginPage;
  };
}

decorate(CommonSettingsStore, {
  windowHeight: observable,
  internetSpeed: observable,
  isLoggedIn: observable,
  isMobile: observable,
  showLoginPage: observable,
  isDarkMode: observable,
  linkName: observable,
  toggleTheme: action,
  setWidth: action,
  setHeight: action,
  setInternetSpeed: action,
  handleLogout: action,
  handleLogin: action,
  toggleLoginPage: action,
});

export default CommonSettingsStore;
