import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { ReactComponent as BannerMobile } from "../../assets/svg/banner-mobile.svg";
import { ScreenBanner } from "./styles";
import { MainContent } from "./MainContent";
import { Icon } from "../../ui/icon";
import { useStores } from "../../hooks";
import { ampli } from "../../ampli";

const STORAGE_FLAG_KEY = "bannerSwitchedOff";

export const FullScreenBanner = () => {
  const {
    PointStore: {pointInfo},
  } = useStores();

  const [shouldOpen, setShouldOpen] = useState(!sessionStorage.getItem(STORAGE_FLAG_KEY));

  const closeHandler = () => {
    sessionStorage.setItem(STORAGE_FLAG_KEY, "off");
    setShouldOpen(false);
  }

  useEffect(() => {
    if (!shouldOpen || !pointInfo.id) return;

    ampli.bannerShown({
      point_id: pointInfo.id.toString(),
    });
  }, [pointInfo.id, shouldOpen]);

  return shouldOpen ? (
    <FullScreenBannerStyled>
      <CloseIconBox onClick={closeHandler}>
        <Icon name="close" iconColor="#fff" iconColorHover="#fff" />
      </CloseIconBox>
      <MobileImage />
      <MainContent />
    </FullScreenBannerStyled>
  ) : null;
};

export const CloseIconBox = styled.div`
  align-self: flex-end;
  cursor: pointer;
`;

const FullScreenBannerStyled = styled(ScreenBanner)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between
  position: fixed;
`;

const MobileImage = styled(BannerMobile)`
  background-size: contain;
  background-position: center;
  color: white;
  height: 100%;
  width: 100%;
  max-width: 600px;
  margin-bottom: 24px;
`;
